@import "../../../../../../variables";

.InteractionModal {
  .ModalContent {
    position: relative;

    &_CloseButton {
      position: absolute;
      top: -44px;
      right: -12px;
      background-color: transparent;
      border: none;

      &:hover {
        cursor: pointer;
        transition: opacity .2s linear;
        opacity: 0.6;
      }
    }
  }

  .ant-popover-title {
    border: none;
    padding: 0 0 8px;
    min-height: 24px;
  }

  .ant-popover-inner {
    padding: 24px;
  }

  &__Subject {
    font-size: 18px;
    line-height: 28px;
    color: #101828;
  }

  &__Info {
    color: #667085;
    padding-bottom: 2px;
  }

  &__Body {
    padding: 20px 10px 20px 0;
    overflow-y: scroll;
    height: 40vh;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #1C9AFF;
      border-radius: 30px;
    }

    &::-webkit-scrollbar-track {
      background: #FFFFFF;
    }
  }
}

.ant-popover-inner {
  border-radius: 8px !important;
}
