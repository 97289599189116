.AdminPage {
  width: 100%;
  margin-bottom: 20px;

  .SetFreeButton {
    margin-right: 10px;
  }

  &_Card {
    margin-bottom: 20px;
  }

  &_Cards {
    margin: 20px 0;

    .ant-card-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .ant-card {
        margin: 5px;
      }
    }
  }
}