@import "src/variables";

.MessageCreator {
  &_Wrapper {
    display: flex;
    border-radius: 16px;
  }

  &_FormWrapper {
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100%;
  }

  &_Contacts {
    background-color: $primary-white;
    padding: 8px;
    width: 23%;
    border-radius: 10px;

    div {

      &::-webkit-scrollbar-thumb {
        background: $primary-black;
      }

      &::-webkit-scrollbar {
        width: 3px;
      }
    }

  }

  &_ContactContainer {
    display: flex;
    margin-bottom: 10px;
    color: $primary-black;
    font-family: $inter-font;
    font-size: 12px;
    border-radius: 4px;
    background-color: #FCFCFF;
    text-align: left;
    border: 1px solid #E1E2EA;
    padding: 10px 10px 10px 15px;
    font-weight: 500;

    &_Label {
      text-overflow: ellipsis;
      overflow: hidden !important;
      white-space: nowrap;
      display: flex !important;
      flex-direction: row;
      column-gap: 6px;
      align-items: center;

    }

    &:hover {
      cursor: pointer;
      border: 1px solid #85C9FF;
    }


    &.active {
      font-weight: bold;
      background: #F2F9FF;
      border: 1px solid #85C9FF;
    }

    &.invalid {
      font-weight: bold;
      border: 1px solid $light-red;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: $lighterer-gray;
      text-decoration: line-through;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &_Title {
    color: $dark-blue;
    font-weight: bold;
    padding-bottom: 8px;
  }

  &_Personalization {
    line-height: 35px;
    margin-bottom: 0;
  }


  &_Button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px;
    padding: 4px 8px;
    border-radius: 8px;
    color: $primary-blue;
    font-weight: bold;

    &.inactive {
      opacity: 0.3;

      &:hover {
        cursor: not-allowed;
      }
    }

    &.active:hover {
      cursor: pointer;
    }
  }

  &_PickerWrapper {
    position: relative;
    display: inline-block;
    margin: 12px;
  }

  &_Scheduler {
    &_DeleteButton {
      cursor: pointer;
      position: absolute;
      top: -8px;
      right: -11px;
      color: $primary-black;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  &_Picker {
    background: #F2F9FF;
    border: 1px solid #85C9FF;
    border-radius: 4px;
    width: 250px;
    padding: 6px;

    .ant-picker-input {
      column-gap: 5px;
      flex-direction: row-reverse;

      input {
        font-family: $inter-font;
        color: $primary-black !important;
      }
    }

    &_Icon {
      color: $primary-blue;
    }
  }

  &_SchedulerWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &_SchedulerButton {
    display: flex;
    flex-direction: row;
    color: $dark-gray;
    font-weight: bold;
    font-size: 12px;

    span {
      margin: auto;
    }

    span:nth-child(2) {
      color: $light-gray;
    }
  }

  &_PreviewText {
    font-family: $brandonGrotesque-font;
    font-size: 16px;
    text-align: left;
    margin: 20px 0;
    display: block;
  }

  &_ConfirmButton {
    margin: 20px;
  }

  &_FooterButtons {
    display: flex;
    justify-content: space-between;
  }

  &_SubmitButton {
    margin-top: 24px;
    padding: 6px 20px;
    text-align: center;
    background-color: $light-blue;
    color: $primary-black;
    font-size: 14px;
    font-weight: bold;
    font-family: $inter-font;
    border-radius: 4px;
    box-shadow: none;
    width: 120px;
    cursor: pointer;

    &:hover {
      background-color: $light-blue;
      color: $primary-black;
    }

    &:focus {
      background-color: $light-blue;
      color: $primary-black;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: $lighterer-gray;
      text-decoration: line-through;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &_CancelButton {
    margin-top: 24px;
    padding: 6px 20px;
    text-align: center;
    background-color: $lighter-red;
    color: $primary-white;
    font-size: 14px;
    font-weight: bold;
    font-family: $inter-font;
    border-radius: 4px;
    box-shadow: none;
    width: 200px;
    cursor: pointer;

    &:hover {
      background-color: $light-red;
      color: $primary-white;
    }

    &:focus {
      background-color: $light-red;
      color: $primary-white;
    }
  }


  &_TemplateCol {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 12px;
  }
}
