@import '../../../../../variables.scss';
@import '../Tabs.style';

.CreateNote {
  @include Tab_Container;

  &_Container {
    width: 100%;
  }

  @include Tab_Textarea;

  @include Tab_Footer;
}


.AddNotePopover {
  @include Tab_Popover;
}