@import "../../../variables";

.SpheresInput {
  background-color: #f8f8f8 !important;
  border: none !important;
  border-radius: 0 !important;
  height: 3rem;

  &:focus {
    box-shadow: none !important;
  }
}

.SpheresSelector {
  &_SpheresPopup {
    position: relative;
    flex-direction: column;
    width: 15rem;
    height: 10rem;
    overflow-y: auto;
    background-color: #fff !important;
    border-radius: 6px !important;
    padding: 0 10px;

    .SphereRow {
      font-family: $main-font;
      padding-bottom: 10px !important;
      padding-top: 10px !important;
      border-bottom: 1px solid rgba($light-gray, 0.4) !important;

      label {
        font-size: 14px !important;
        font-weight: 600 !important;
        color: $dark-blue !important;
      }
    }

    &_SubmitButton {
      background-color: $light-blue;
      text-align: center !important;
      color: $white;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      &:hover {
        cursor: pointer;
      }

      &-text {
        font-size: 16px;
        font-weight: 500;
        color: $white !important;
      }

      &.disabled {
        background-color: $strengths-weak;
      }
    }
  }

  &_SpheresContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 50px;

    &_Placeholder {
      &-text {
        font-family: $main-font !important;
        font-size: 15px !important;
        font-weight: 400 !important;
        font-style: italic;
        color: $silver-blue !important;
      }

      &-text:hover {
        color: $middle-blue !important;
        cursor: pointer;
      }

      &-container {
        flex: 1;
        padding-left: 10px;
        align-items: center;
      }
    }

    &_Sphere {
      &-container {
        border-radius: 7px;
        background-color: #e4f3fe;
        display: inline-block;
        margin: 0 0 5px 8px;
        padding: 2px 8px;

        &.new {
          padding: 5px;
        }

        &.plus {
          background-color: transparent !important;

          &:hover {
            cursor: default;
            box-shadow: none;
            background-color: transparent !important;
          }
        }

        &:hover {
          transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
          cursor: pointer;
          box-shadow: 1px 1px 2px #94b7c0;
          background-color: #4baaf6;
        }
      }

      &-text {
        font-size: 12px;
        font-weight: 500;
        color: $primary-black;
        word-break: break-word;

        &.new {
          font-size: 16px !important;
        }
      }
    }
  }

  &_NewSphere {
    &-container {
      padding: 10px;
    }

    &-text {
      font-family: $main-font !important;
      font-size: 16px !important;
      font-weight: 600 !important;
      color: $dark-blue !important;
    }
  }
}

.ant-popover-inner-content {
  padding: 0 !important;
}

.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $light-gray !important;
}

.ant-checkbox-checked::after {
  border: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $light-blue !important;
  border: none !important;
}
