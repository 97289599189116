@import '../../../variables';

.ImportPage {
  flex: 1;
  overflow-y: auto !important;
  padding-bottom: 5rem;

  h3 {
    margin-bottom: 2rem;
    padding-left: 1rem;
  }

  &_HeaderCheckbox {
    &-container {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: .5rem 1rem;
    }

    &-checkbox {
      span {
        font-family: $main-font !important;
        color: $dark-blue !important;
        font-weight: bold !important;
        font-size: 14px !important;
      }
    }

    &-label {
      font-family: $main-font !important;
      font-size: 14px !important;
      color: $light-gray !important;
    }
  }

  &_TopicsSelector {
    &-container {
      width: 40%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: .5rem 1rem;
    }

    &-label {
      flex: 1;
      font-family: $main-font !important;
      color: $dark-blue !important;
      font-weight: bold !important;
      font-size: 14px !important;
    }
  }

  &_ExpertisesSelector {
    &-container {
      width: 40%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: .5rem 1rem;
    }

    &-label {
      flex: 1;
      font-family: $main-font !important;
      color: $dark-blue !important;
      font-weight: bold !important;
      font-size: 14px !important;
    }
  }

  &_SpheresSelector {
    &-container {
      width: 40%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: .5rem 1rem;
    }

    &-label {
      flex: 1;
      font-family: $main-font !important;
      color: $dark-blue !important;
      font-weight: bold !important;
      font-size: 14px !important;
    }
  }

  &_Table {
    font-family: $main-font;

    .ant-table-column-sorter {
      position: absolute !important;
      top: 30% !important;
    }

    ::-webkit-scrollbar {
      width: 10px !important;
      border-radius: 5px !important;
      background: $primary-blue !important;

      &-thumb {
        background-color: rgba($silver-blue, .3) !important;
        border-radius: 5px !important;
      }
    }

    &_Row {
      &:hover > td {
        background-color: $white !important;
      }

      td {
        padding: 8px 5px !important;
        vertical-align: middle !important;
        color: $dark-blue;
        font-weight: bold;

        &.hoverable {
          &:hover {
            transition: all .5s cubic-bezier(.17, .67, .83, .67);
            background-color: #E6F7FF !important;
          }
        }
      }
    }

    th {
      font-size: 10px !important;
      font-weight: 400 !important;
      color: $light-gray !important;

      & span {
        white-space: nowrap !important;
        text-wrap: none !important;
        text-overflow: ellipsis !important;
      }
    }

    &_Header {
      &-container {
        width: 200px
      }

      &-select {
        width: 200px;
      }
    }

    &_Cell {
      &-value {
        color: $dark-blue;
        font-size: 14px;
      }

      &-label {
        margin-left: 0.2rem;
        color: $dark-gray;
        font-size: 10px;
      }

      &-sphere {
        border-radius: 4px !important;
        background-color: $primary-blue !important;
        font-family: $main-font !important;
        font-size: 13px !important;
        font-weight: 400 !important;
        color: $primary-white !important;
        padding: 0.2rem 0.3rem;
        margin: 0.2rem;
        display: inline-block;
      }
    }
  }

  &_SubmitButton {
    &-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: .5rem 1rem;
    }

    &-label {
      flex: .3;
      font-family: $main-font !important;
      color: $dark-blue !important;
      font-weight: bold !important;
      font-size: 14px !important;
    }

    &-button {
      flex: .05;
      background-color: $light-blue !important;
      font-size: 14px;
      font-weight: bold;
      color: $white !important;

      &:hover, &:focus {
        border-color: $light-blue !important;
      }

      &:disabled {
        background-color: $light-gray !important;
        color: $white !important;
        opacity: .5;
      }
    }
  }
}

.ant-table-fixed-left {
  & td {
    background-color: $white !important;
  }
}


