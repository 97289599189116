
@import '../../../variables.scss';


.EmptyState {

  &_Size_large {
    width: 80%;
    margin: 2rem auto;
    font-size: 30px;
  }

  &_Size_medium {
    width: 90%;
    margin: 1rem auto;
    font-size: 20px;
  }


  &_Quote {
    width: 100% !important;
    text-align: left;
    font-family: $main-font !important;
    font-weight: 300;
    font-style: italic;
    color: $light-gray !important;
    font-size: 1em !important;

    span {
      width: 100% !important;
      text-align: left;
      font-family: $main-font !important;
      font-weight: 300;
      color: $light-gray !important;
      font-size: 0.75em !important;
      display: block;
    }
  }

  &_Instructions {
    color: $dark-blue !important;
    font-family: $main-font !important;
    font-size: 0.75em !important;
  }
}