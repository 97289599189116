@import '../../../variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');

.game {
  //background-color: #FF82E8;
  height: 100vh;
  background: linear-gradient(-45deg, #FF82E8, #8DCBFE, #FFB8F2, #FBE8B9);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;


  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .nav {
    height: 30px;
    position: relative;

    .logo {
      height: 90px;
      text-align: center;
      margin: 0 auto;
    }

    .closeButton {
      display: inline-block;
      cursor: pointer;
      z-index: 1000;
      padding: 20px;
      position: absolute;
      top: 0;
      left: 0;

      .anticon-close-circle {
        svg {
          width: 30px;
          height: 30px;
          fill: #FBFBFB
        }

        &:hover {
          svg {
            fill: #404040;
          }
        }
      }
    }
  }

  .general-instructions {
    font-family: 'Lilita One', cursive;
    font-size: 16px;
    margin: 8px auto;
    text-align: center;
    display: block;
    color: #5C5C5C;
  }

  .option-button {
    border-radius: 16px !important;
    background-color: #FFB8F2;
    color: $primary-black;
    border: 4px solid #FF71E4;
    margin: 8px 16px 0;
    width: calc(100% - 32px);
    padding: 6px 20px;
    line-height: 32px;
    height: auto;
    font-family: 'Lilita One', cursive;
    font-size: 28px;

    &.ant-btn-primary, &:hover {
      border-color: $primary-off-white;
    }

    &:active {
      border-color: $primary-lila;
    }

    .element {
      display: table;
      table-layout: fixed;
      width: 100%;

      .truncate {
        display: table-cell;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .addNewSphere {
    display: grid;
    grid-template-columns: auto 160px;

    &_Input {
      background-color: #f5f6fa;
      color: $primary-black;
      border: 4px solid #FF71E4;
      outline: none;

      &:focus {
        border-color: #f5f6fa;
      }
    }
  }

  #game-area {
    height: 80vh;
    width: 100%;
    position: relative;
  }

  .restart-button {
    position: absolute;
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .restart-button-button {
      background-color: #F8D98A;
      border-radius: 16px;
      color: #000;
      padding: 16px;
      font-family: 'Lilita One', cursive;
      font-size: 28px;
      cursor: pointer;

      &:hover {
        background-color: #FFA6EF;
      }

    }
  }


  .hidden {
    display: none;
  }

  .card {
    position: absolute;
    width: 100%;
    height: 80vh;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card > .card-inner {
    background-color: white;
    background-size: auto 85%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 45vh;
    max-width: 300px;
    height: 75vh;
    max-height: 570px;
    will-change: transform;
    border-radius: 10px;
    box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
    overflow-y: auto;
    overflow-x: hidden;

    .card-body {
      padding: 20px;
      text-align: center;

      .avatar-placeholder {
        margin: 30px auto;
        display: inline-block;
        background-color: #F4C041;
        border-radius: 60px;
        height: 120px;
        width: 120px;
        margin-bottom: 0px;

      }

      h3 {
        margin-top: 30px;
        font-family: 'Lilita One', cursive !important;
        font-size: 22px !important;
        word-break: break-all;

        span {
          display: block;
          font-family: 'Lato', sans-serif;
          font-size: 18px;
          word-break: break-all;
        }
      }

      hr {
        margin: 40px 30px;
      }

      .conversation {
        background-color: #E2F2FF;
        border-radius: 16px;
        padding: 8px;
        word-break: break-all;

        h4 {
          font-weight: bold;
          font-size: 16px;
          word-break: break-all;
        }

        .subject {
          font-weight: bold;
          font-size: 14px;
          word-break: break-all;
        }
      }

    }
  }


  .controls {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    .ant-btn {
      border: none;
      margin: 8px;
      padding: 8px;
      height: auto;
      font-family: 'Lilita One', cursive;
      font-size: 22px;

      small {
        display: block;
        font-size: 14px;
        opacity: 0.7;
      }
    }
  }

  .filterRow {
    margin: 10px 20px;

    label {
      font-family: 'Lilita One', cursive !important;
      font-size: 22px !important;
      color: #1F1F1F !important;
    }

    input {
      font-family: 'Lilita One', cursive;
      border: none;
      background-color: transparent;
      color: #1F1F1F;
      border-bottom: 2px solid #1F1F1F;
      font-size: 18px;
    }
  }

  .spheresSection {
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      width: 4px !important;
      background-color: #FF71E4 !important;
    }

    &::-webkit-scrollbar-track {
      background: #FFB8F2;
    }
  }

}
