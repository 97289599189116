@import '../variables';

@mixin my-ant-drawer-header {
  .ant-drawer-header {
    padding: 20px 20px 10px;
    border: none;

    .ant-drawer-title {
      font-size: 24px;
      font-weight: 600;
      font-family: $recoleta-font;
    }
  }
}

@mixin my-ant-checkbox {
  .ant-checkbox-wrapper {
    font-family: "Inter", sans-serif !important;
    font-size: 15px !important;
    font-weight: 400;
    color: #020202 !important;

    .ant-checkbox-inner {
      border-radius: 4px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: rgba(28, 154, 255, 0.1) !important;
      border: 1px solid #1C9AFF !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner:after {
      border-color: #1C9AFF !important;
    }
  }
}

@mixin my-ant-multi-select {
  .ant-select-selector {
    padding-left: 48px;
    background-color: rgba(255, 255, 255, 0.75) !important;
    border: none !important;
    border-radius: 4px !important;

    .ant-select-selection-placeholder {
      color: $placeholder-font-color;
      left: 55px;
    }

    .ant-select-selection-item {
      height: 24px;
      line-height: 22px;
    }
  }
}

@mixin remove-outline-on-select {
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
}

@mixin my-ant-form-item-error {
  .ant-form-item-explain-error {
    position: absolute;
    bottom: 0;
    min-height: auto !important;
    margin: 4px 10px 0;
    font-family: $inter-font;
    font-size: 12px !important;
  }
  .ant-form-item-explain-success {
    min-height: 0 !important;
    height: 0 !important;
  }
}