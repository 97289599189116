@import '../../../variables';

.ProgressBar {
  position: relative;
  width: 100%;
  border: 2px solid #FFFFFF;
  border-radius: 2px;
  background-color: #FFFFFF;
  color: #FFFFFF;
  font-size: 13px;
  height: 24px;
  margin: 5px 0 2px;

  &_Back {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    width: 100%;
    height: 20px;
    background: #1C9AFF;
    color: white;
  }

  &_Front {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -2px;
    padding-bottom: 1px;
    background: white;
    color: black;
    transition: clip-path 1s linear;
  }
}