@import "../../../variables";

.StripeSessionCreatingModal {
  border-radius: 10px !important;

  .ant-modal-content {
    border-radius: 10px;
  }

  &_Body {
    padding: 50px;
    text-align: center;

    &_Processing {
      font-size: 32px;
      margin-bottom: 50px;
    }

    span {
      font-size: 16px;
    }
  }
}