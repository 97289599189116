@import '../../../variables';

.LocationFinder {
  width: 100%;

  &_Dropdown {
    background-color: #fff !important;
    border-radius: 6px !important;
    padding-top: 0px;

    li {
      font-family: $main-font;
      font-size: 14px !important;
      font-weight: 600 !important;
      color: $dark-blue !important;
      padding-bottom: 10px !important;
      padding-top: 10px !important;
      border-bottom: 1px solid rgba($light-gray, 0.4) !important;

      i {
        display: none !important;
      }
    }
  }

  .ant-input:focus {
    border: 1px #40a9ff solid !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
  }
}