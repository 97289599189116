@import "../../../../../variables";

#sectionActivity {
  margin-bottom: 20px;

  .SectionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    margin: 5px 0 15px;
  }

  .SectionActivity {
    &_TeamInteractions {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: 50px 1fr;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      padding: 10px 12px;
      margin-bottom: 10px;
      background-color: #ddf0fd;

      &_Icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #ffffff;
      }

      p {
        margin: 0;
      }
    }

    &_Loading {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 20px;
      margin-top: 15px;
      color: #909ca8;

      span:nth-child(2) {
        font-size: 14px;
      }
    }

    &_Reminders {
      display: grid;
      grid-gap: 10px;
    }

    &_LoadMore {
      display: block;
      color: #1c9aff;
      margin: 0 0 20px 12px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
