@import '../../../../../variables';

.ContactDrawerNavSections {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EAECF0;
  margin-top: 22px;

  a {
    text-align: center;
    font-weight: 500;
    color: #868686;
    padding-bottom: 17px;
    transition: all .4s linear;

    &:hover {
      color: #454545;
    }
  }

  a.activeDetails {
    color: #C79A25;
  }

  a.activeContact {
    color: #D833BB;
  }

  a.activeNotes {
    color: #40BA2C;
  }

  a.activeActivity {
    color: #0E7ED8;
  }

  a.activeReminders {
    color: #000000;
  }

  &_ActiveLine {
    position: absolute;
    height: 2px;
    border: none;
    bottom: 0;
    margin: 0;
    background-color: #0E7ED8;
    transition: all .4s linear;

    &--Details {
      background-color: #C79A25;
    }

    &--Contact {
      background-color: #D833BB;
    }

    &--Notes {
      background-color: #40BA2C;
    }

    &--Activity {
      background-color: #0E7ED8;
    }

    &--Reminders {
      background-color: #000000;
    }
  }
}
