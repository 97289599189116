@import '../../../variables';

.SearchPeople {
  position: relative;
  width: 100%;

  &::before {
    content: "";
    background-image: url("../../Icons/MagnifyingGlass.svg");
    position: absolute;
    top: 10px;
    left: 15px;
    z-index: 2;
    width: 24px;
    height: 24px;
  }

  input {
    background-color: #F2F4FA;
    font-size: 14px;
    border: none;
    padding: 11px 11px 11px 50px;
    width: 100%;
    outline: none;
  }
}
