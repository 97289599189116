@import '../../../../../variables.scss';
@import '../../../../../scss/customAntd';

.MultiSelectWithList {
  @include my-ant-multi-select;
  position: relative;
  width: 100%;
  margin-top: 18px;
  font-size: 13px;
  background-color: transparent;

  &::before {
    content: "";
    background-image: url("../../../../Icons/MagnifyingGlass.svg");
    position: absolute;
    top: 7px;
    left: 15px;
    z-index: 1001;
    width: 24px;
    height: 24px;
  }
}