@import "src/variables";


.AttachmentsUpload {

  &_Wrapper span {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;

    .ant-upload-list {
      display: flex !important;
      overflow-x: scroll;
      flex-flow: row-reverse;

      &::-webkit-scrollbar {
        height: 4px;
      }
    }

    .ant-upload-list-text-container, .ant-upload-list-picture-container {
      flex: 1 0 20%;
      margin: 12px;
      transition: opacity 0.3s, height 0.3s;
    }
  }

  &_UploadButton {
    color: $dark-gray;
    font-weight: bold;
    font-size: 12px;

  }

  &_FileRender {
    padding: 6px;
    background-color: #FCFCFF;
    border: 1px solid #E1E2EA;
    border-radius: 4px;
    font-size: 14px;
    font-family: $inter-font;
    position: relative;

    span {
      margin-right: 8px;
    }

    &_DeleteButton {
      cursor: pointer;
      position: absolute;
      top: -10px;
      right: -14px;
      color: $primary-black;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }



}
