.WelcomeChecklist {
  background-color: #FFE0F9;
  display: grid;
  padding: 16px 16px 4px 16px;
  border-radius: 6px;
  margin-bottom: 15px;

  p {
    display: flex;
    align-items: center;
    color: #344054;
    margin: 0;
    padding-bottom: 12px;

    svg {
      margin-right: 17px;
    }

    a {
      color: #344054;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }
}
