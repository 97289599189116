@import "../../../variables";

.OnboardingScreens {
  display: grid;
  width: 100%;
  height: 100%;
  padding: 8px 0;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  justify-content: center;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 30px;
    height: 240px;
  }

  &::-webkit-scrollbar-track {
    background: #d0cfcf;
  }

  .ant-drawer-content {
    background-color: #93cefe;
  }

  &_Step {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &_Header {
      h1 {
        color: #ffffff;
        text-align: center;
        font-family: $albertSans-font;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }

    &_Body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px 0;

      &_Image {
        margin-bottom: 16px;
        width: 340px;
        height: 328px;
      }

      p {
        text-align: center;
        color: #ffffff;
        font-family: $inter-font;
        font-size: 16px;
        max-width: 700px;
      }

      &_Choices {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 16px;

        button {
          width: fit-content;
        }
      }
    }

    button {
      height: fit-content;
      border: none;
      padding: 8px 14px;
      background-color: #32d583;
      color: #ffffff;
      border-radius: 8px;

      &:hover {
        cursor: pointer;
        transition: background-color 0.2s linear;
        background-color: #2ccf7a;
      }
    }

    &_Footer {
      display: grid;
      justify-content: center;

      button.OnboardingScreens_Step_Footer_DismissButton {
        border: none;
        background-color: transparent;
        text-decoration: underline;
        color: #f8fafc;

        &:hover {
          cursor: pointer;
          transition: opacity 0.2s linear;
          opacity: 0.5;
        }
      }
    }
  }

  &_StepNumberInfo {
    display: flex;
    justify-content: center;
    gap: 8px;
    padding: 24px 0;

    &_Step {
      background-color: #c9e7ff;
      width: 21px;
      height: 21px;
      border-radius: 50%;

      &--active {
        background-color: #ffffff;
      }
    }
  }
}
