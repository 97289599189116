@import "../../../../variables";

.ActivitiesTab {
  margin-left: 10px;
  height: 100%;

  .ant-list {
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7b7b9 !important;
      border-radius: 30px;
    }

    &::-webkit-scrollbar-track {
      background: #f5f6fb;
      margin-bottom: 20px;
    }
  }

  .ant-list-header {
    border-bottom: none !important;
  }

  &_Item {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    pointer-events: none;

    .animate-avatar {
      animation: 500ms ease-out 0s 1 zoomIn forwards;

      @keyframes zoomIn {
        0% {
          transform: scale(0.7);
        }
        80% {
          transform: scale(1.05);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    &_Content {
      font-family: $main-font;
      color: $dark-blue;

      &.error {
        color: $error !important;
      }

      b {
        font-weight: bold;
        cursor: pointer;
        pointer-events: initial;
      }
    }

    &_Icon {
      color: $dark-blue;
    }

    &_Date {
      font-family: $main-font;
      color: $light-gray;
    }

    &_Points {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      padding: 2px 3px;
      border-radius: 5px;
      color: $primary-white;
      background-color: $primary-blue;
    }
  }

  &_Recent {
    margin-top: 30px;
  }

  &_FilterContainer {
    align-items: center;
    font-family: $main-font;
    color: $primary-black;
  }

  &_Select {
    text-decoration-line: underline;
    font-weight: bold;

    .ant-select-arrow {
      color: $primary-black;
    }

    .ant-select-selector {
      padding-left: 0 !important;
    }
  }

  &_ScoreContainer {
    font-family: $main-font;
    margin-bottom: 25px;
    display: flex;
    column-gap: 10px;
  }

  &_ScoreWrapper {
    background: $primary-blue;
    border-radius: 5px;
    padding: 4px 6px;
    align-items: center;
    display: flex;
  }

  &_Score {
    margin-bottom: 0 !important;
    display: inline;
    font-weight: normal !important;
    color: $primary-white !important;
  }

  &_ScoreCol {
    align-self: center;
  }

  &_BoldText {
    font-family: $recoleta-font;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-list-item {
    &:nth-of-type(1) {
      .ActivitiesTab_PlaceBadge {
        background-color: rgba(28, 154, 255, 0.13);
      }
    }

    &:nth-of-type(2) {
      .ActivitiesTab_PlaceBadge {
        background-color: rgba(255, 113, 228, 0.18);
      }
    }

    &:nth-of-type(3) {
      .ActivitiesTab_PlaceBadge {
        background-color: rgba(244, 192, 65, 0.22);
      }
    }
  }

  &_PlaceBadge {
    border-radius: 2.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-family: $recoleta-font;
    font-size: 15px;
    width: 25px;
  }

  &_Highscores {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin-bottom: 20px;
  }

  &_HighscoresList {
    border: 2px solid $primary-blue;
    border-radius: 5px;
    padding: 6px 12px;
    width: 70%;
    box-shadow: 0 3.27704px 92.5765px rgba(105, 187, 253, 0.21);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-list-item-meta {
      align-items: center;
    }

    &_Switch {
      display: flex;
      justify-content: flex-end;

      button {
        border: none;
        background-color: transparent;
        padding: 0 5px;
        margin: 0;

        &.clickable {
          color: $primary-blue;
          text-decoration: underline;

          &:hover {
            opacity: 0.7;
            transition: opacity 0.3s ease-in-out;
            cursor: pointer;
          }
        }
      }
    }
  }

  .ant-list-item-meta-title {
    margin: 0;
  }

  &_LeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;

    &--me {
      background-color: #fdf7e8;
      border-radius: 5px;
    }

    :first-child {
      text-overflow: ellipsis;
      overflow: hidden !important;
      white-space: nowrap;
    }
  }

  &_ImageContainer {
    width: 30%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &_LinkContainer {
    font-size: 12px;
    color: $primary-black;
  }

  &_Link {
    cursor: pointer;
    text-decoration-line: underline;
    font-weight: bold;

    &:hover {
      text-decoration-line: underline;
    }
  }

  //&_Spin {
  //  background-color: $primary-white !important;
  //  z-index: 3 !important;
  //}

  &_Footer {
    font-weight: bold;
  }
}
