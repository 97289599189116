@import "../../../variables";
@import "../../../scss/customAntd";

.WrapSyncingContactsModal {
  background: rgba(52, 64, 84, 0.7);
  backdrop-filter: blur(8px);
}

.SyncingContactsModal {
  font-family: "Inter", sans-serif;
  text-align: center;

  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;

    //Modal Header
    .ant-modal-header {
      padding: 24px 24px 0;
      border-bottom: none;

      //Modal Title
      .ant-modal-title {
        text-align: left;

        h1 {
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          color: #101828;
        }

        p {
          font-size: 14px;
          line-height: 20px;
          color: #667085;
        }
      }
    }
  }

  &_Body {
    padding: 0 24px;
    @include my-ant-checkbox;

    .ant-checkbox-wrapper {
      font-weight: 500;
      font-size: 14px !important;
      font-family: "Inter", sans-serif !important;
      color: #344054 !important;
      padding: 4px;
    }

    &_CheckboxGroup {
      display: flex !important;
      flex-direction: column;
    }

    &_CheckAll {
      width: 100%;
    }
  }

  .ant-modal-footer {
    padding: 24px;
    border: none;
    display: grid;
    grid-template-columns: 1fr 1fr;

    button {
      height: 44px;
      color: #344054;
      font-weight: 500;
      font-size: 16px;
    }

    .ant-btn-primary {
      color: white;
    }
  }
}