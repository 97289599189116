@import "../../../variables";

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes fade-out-in {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in_delayed {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.FocusMode {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  z-index: 5 !important;
  animation: fade-out-in 1s;
  opacity: 1;

  &--contactDrawerVisible {
    .ant-drawer-body {
      width: calc(100% - 430px);
    }

    .FocusMode_Content {
      width: 80%;
    }

    .FocusMode_CloseButton {
      right: 446px;
    }
  }

  .ant-drawer-content-wrapper {
    background: linear-gradient(-45deg, #9dd4ff, #1c9aff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    top: 0;
  }

  .ant-drawer-content {
    height: 100vh;
    background-color: transparent;
  }

  &_CloseButton {
    animation: fade-in_delayed 1.4s;
    background-color: transparent;
    border: none;
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 0;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
      transition: opacity 0.2s linear;
    }
  }

  &_LogoBox {
    animation: fade-in_delayed 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  &_Content {
    animation: fade-in_delayed 1.4s;
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 36px auto 100px;
    min-height: calc(100% - 304px);
    width: 60%;
    min-width: 800px;
    border-radius: 16px;
    background-color: white;
    padding-bottom: 36px;

    @media (max-width: 1200px) {
      width: 80%;
    }

    .Scene {
      animation: fade-in 1.4s;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 60px;

      h1 {
        font-family: $fredoka-font;
        font-size: 44px;
        font-weight: 600;
        text-align: center;
        color: #5e5e5e;
      }

      h2 {
        font-family: $fredoka-font;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        color: #5e5e5e;
      }

      h3 {
        font-family: $inter-font;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        color: #5e5e5e;
      }

      button {
        display: flex;
        padding: 8px 14px;
        justify-content: center;
        align-items: center;
        width: 300px;
        border-radius: 8px;
        background-color: #ffffff;
        border: 1.6px solid #1c9aff;
        color: #1c9aff;
        margin-bottom: 16px;

        &:hover {
          cursor: pointer;
          border: 1.6px solid #349cef;
          color: #349cef;
          background-color: #f1f0f0;
          transition: 0.3s;
        }

        &.primary {
          border: none;
          color: white;
          background-color: #1c9aff;

          &:hover {
            color: white;
            background-color: #3ea5f8;
          }
        }
      }
    }

    .StartScene {
      margin-top: 40px;
    }

    .ReviewingTriggerScene {
      b {
        margin: 0 3px;
      }
    }

    .RecordingInteractionScene {
      p {
        font-family: $inter-font;
        color: #5e5e5e;
        font-size: 16px;
      }

      &_Methods {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 36px;
        margin: 2px 0 16px;
        color: #909ca8;

        span:hover {
          cursor: pointer;
          opacity: 0.8;
          transition: opacity 0.2s linear;
        }

        a {
          color: #909ca8;
        }

        .active {
          color: #1c9aff;
        }

        .disabled {
          color: #bdbdbd;

          &:hover {
            cursor: not-allowed;
            opacity: 1;
          }
        }
      }
    }

    .FinalScene {
      &::before {
        content: "";
        background-image: url("./../../../images/Badge.svg");
        position: absolute;
        top: -80px;
        left: -80px;
        z-index: 0;
        width: 185px;
        height: 247px;
      }

      h1 {
        font-size: 56px;
      }

      &_Summary {
        p {
          margin: 0;
          color: #9498a8;
          font-family: $fredoka-font;
          font-size: 30px;

          span {
            color: #5e5e5e;
          }
        }
      }
    }
  }
}
