@import "../../../variables";

.StrengthSelector {
  min-width: 50px !important;
  padding: 0 !important;

  label {
    font-family: $main-font;
    font-size: 13px !important;
    color: $light-gray;
  }

  .ant-tooltip-content {
    .ant-tooltip-inner {
      padding: 0 !important;
      background-color: #fff !important;
    }

    .ant-tooltip-arrow {
      display: none !important;
    }
  }

  &_Option {
    padding: 5px 10px;
    width: 225px;

    &:hover {
      background-color: rgba($light-blue, .1);
    }
  }
}