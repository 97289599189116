@import "../../../../../variables";

.ContactCard {
  display: grid;
  grid-template-columns: 74px 1fr;
  align-items: center;
  grid-gap: 26px;
  padding: 8px 10px;
  min-width: 320px;
  max-width: 360px;
  height: 106px;
  border: 1px solid black;
  border-radius: 8px;
  background-color: white;
  transition: background-color 0.2s linear;

  &--person {
    &:hover {
      cursor: pointer;
      background-color: #f7f7f7;
    }
  }

  h4 {
    font-family: $inter-font;
    color: #4b5565;
    font-size: 21px;
    font-weight: 600;
    margin: 0;
    max-width: 220px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &_Spheres {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    align-items: center;
    margin-bottom: 4px;

    span {
      align-items: center;
      background-color: #9dd4ff;
      border-radius: 16px;
      height: 22px;
      text-align: center;
      padding: 2px 8px;
      font-size: 12px;
      color: #000000;
      max-width: 80px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &_LastContacted {
    font-family: $inter-font;
    font-size: 12px;
    margin: 0;

    &--participant {
      margin-top: -4px;
    }
  }

  &_Body {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    color: #868686;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
  }
}
