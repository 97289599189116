.OnboardingCal {
  margin: 24px 0;
  width: 100%;

  .ewebinar__RegForm {
    font-family: inherit;
    padding: 0;
    text-align: left;
    min-width: 300px;
    max-width: 450px;
    margin: auto;
  }

  .ewebinar__RegForm a:hover,
  .ewebinar__RegForm a:visited,
  .ewebinar__RegForm a:link,
  .ewebinar__RegForm a:active {
    text-decoration: none;
  }

  .ewebinar__RegForm * {
    box-sizing: border-box;
  }

  .ewebinar__RegForm:not(.loading) .ewebinar__Dots {
    opacity: 0;
    animation: none;
  }

  .ewebinar__RegForm__Content {
    padding: 1.5rem;
  }

  .ewebinar__RegForm:not(.ewebinar__RegForm--horizontal)
    .ewebinar__RegForm__Content {
    padding-bottom: 0.25rem;
  }

  .ewebinar__RegForm__Footer {
    padding: 1.5rem;
    padding-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .ewebinar__RegForm .ewebinar__RegisterButton,
  .ewebinar__RegForm .ewebinar__RegisterButton__Wrap {
    max-width: unset !important;
    width: 100%;
  }

  .ewebinar__RegForm__Field {
    margin-bottom: 1.25rem;
  }

  .ewebinar__RegForm__Field input,
  .ewebinar__RegForm__Field select {
    font-size: inherit;
  }

  .ewebinar__RegForm__Field input {
    display: block;
    width: 100%;
    line-height: 1.21428571em;
    font-family: inherit;
    padding: 0.67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    box-shadow: none;
  }

  .ewebinar__RegForm__Field input::placeholder {
    color: #666666 !important;
  }

  .ewebinar__Session__Dropdown::after {
    position: absolute;
    content: "⌄";
    font-weight: 600;
    display: block;
    right: 1em;
    top: 50%;
    transform: translate(-10%, -75%) scale(2, 1.5);
    pointer-events: none;
  }

  .ewebinar__RegForm__Error,
  .ewebinar__RegForm__Field__Error {
    color: #ff7470;
    font-size: 0.8rem;
    padding: 0.5833em 0.833em;
    display: none;
  }

  .ewebinar__RegForm__Error {
    margin-top: 0;
    padding: 0.5833em 0;
    margin-bottom: 0 !important;
    text-align: center;
  }

  .ewebinar__RegForm__Field__Error {
    display: none;
    position: relative;
    background: #fff;
    border: 1px solid #ff7470;
    border-radius: 0.28571429rem;
    margin-top: 0.5rem;
  }

  .ewebinar__RegForm__Field__Error::before {
    position: absolute;
    content: "";
    background: #fff;
    border-left: 1px solid;
    border-top: 1px solid;
    z-index: 2;
    width: 0.6666em;
    height: 0.6666em;
    margin-top: -1px;
    border-color: inherit;
    border-width: 1px 0 0 1px;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 0;
    left: 50%;
  }

  @media only screen and (min-width: 992px) {
  }

  .ewebinar__RegForm__Field .ewebinar__Session__Dropdown__Select {
    height: auto;
    min-height: 2.58em;
  }

  .ewebinar__Session__Dropdown {
    position: relative;
    border: none;
    background: #fff;
    border-radius: 5px;
    width: 100%;
    outline: none;
    border: 1px solid #444;
    font-size: 1.1em;
  }

  .ewebinar__Session__Dropdown__Select {
    font-family: inherit;
    height: 36px;
    padding: 0 1em;
    opacity: 1 !important;
    padding-right: 2.5rem;
    border: none;
    border-radius: 0.5em;
    font-size: 1em;
    width: 100%;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .ewebinar__Session__Dropdown::after {
    position: absolute;
    content: "⌄";
    font-weight: 600;
    display: block;
    right: 1em;
    top: 50%;
    transform: translate(-10%, -75%) scale(2, 1.5);
    pointer-events: none;
  }

  .ewebinar__Session__Dropdown__Select:invalid {
    color: #dedede !important;
  }

  .ewebinar__Widget select {
    display: flex;
  }

  .ewebinar__Widget * {
    box-sizing: border-box;
  }

  .ewebinar__RegisterButton {
    font-family: inherit;
    box-sizing: border-box;
    font-family: inherit;
    position: relative;
    display: inline-block;
    padding: 0.5em 2em;
    cursor: pointer;
    border-width: 0px;
    outline: none;
    transition: all 0.2s;
    font-weight: 500;
    font-size: inherit;
    box-sizing: border-box;
    margin: 0;
  }

  .ewebinar__RegisterButton * {
    font-family: inherit;
  }

  .ewebinar__RegisterButton:hover {
    transform: scale(1.02);
  }

  .ewebinar__RegisterButton:active {
    box-shadow: none;
  }

  .ewebinar__RegisterButton .ewebinar__ButtonText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline;
    vertical-align: baseline !important;
  }

  @media only screen and (max-width: 614px) {
    .ewebinar__RegisterButton {
      max-width: 320px !important;
      min-height: 42px !important;
      width: 100% !important;
    }
  }

  .ewebinar__Dots {
    opacity: 1;
    animation: 1.5s linear 0s normal forwards 1.5 delayin;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button .ewebinar__Dots {
    background: #ff7470;
    border-radius: 50px;
  }

  .ewebinar__LoadingDot {
    height: 0.5em;
    width: 0.5em;
    border-radius: 100%;
    display: inline-block;
    animation: 1.2s ewebinar-loading-dot ease-in-out infinite;
  }

  .ewebinar__LoadingDot:nth-of-type(2) {
    animation-delay: 0.15s;
    margin-left: 0.5em;
  }

  .ewebinar__LoadingDot:nth-of-type(3) {
    animation-delay: 0.25s;
    margin-left: 0.5em;
  }

  @keyframes delayin {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes ewebinar-loading-dot {
    30% {
      transform: translateY(-35%);
      opacity: 0.3;
    }
    60% {
      transform: translateY(0%);
      opacity: 0.8;
    }
  }
}
