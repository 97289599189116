@import "../../../../../variables";

.UpdateCollection {

  &_Add, &_Remove {
    p {
      margin-bottom: 8px;
    }
  }

  &_Add {
    margin-top: 18px;
  }

  &_Remove {
    margin-top: 22px;
  }
}