@import "../../../variables";
@import "../../../scss/customAntd";

.SelectAll {
  max-width: 500px !important;

  &_TooltipTitle {
    padding: 2px !important;

    button {
      color: white !important;
      text-decoration: underline !important;
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 0;

      &:hover {
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }

  &_Checkbox {
    position: absolute;
    z-index: 3;
    top: 10px;
    left: 11px;
    width: 28px;

    .ant-checkbox {
      padding-left: 12px !important;
    }
  }
}

.CustomizeColumnMenu {
  display: grid !important;
  padding: 12px 12px 0 12px !important;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);

  label {
    margin-bottom: 10px;
  }
}

.PeopleTableWrapper,
.SharedTableWrapper {
  font-family: $inter-font;
  position: relative;

  @include my-ant-checkbox;

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .PeopleTable {
    .engagement {
      padding: 0;
    }

    .spheres {
      padding-left: 10px !important;
    }

    &_Engagement {
      position: absolute;
      left: -64px;
      top: 0;
      width: 6px;
      height: 100%;

      &-good {
        background-color: #40ba2c;
      }

      &-fade {
        background-color: #f4c041;
      }

      &-none {
        background-color: transparent;
      }
    }

    .SpheresColumn {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_CustomizeColumnButton {
        padding: 0;
        width: 18px;
        height: 18px;
        border: none;
        fill: #646464;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
          transition: opacity 0.2s linear;
        }
      }
    }

    &_CanonicalInteractions {
      display: flex;
      align-items: center;
      font-size: 12px;
      gap: 8px;

      &_Icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #e4f3fe;
      }
    }
  }

  .PeopleTable,
  .SharedTable {
    position: relative;
    margin-bottom: 25px;

    .ant-table-column-sorter {
      display: none;
    }

    .ant-table-container {
      box-shadow: none !important;
    }

    &_SorterIcon {
      margin-left: 2px;
    }

    //Hide antd sorter icons
    .ant-table-container::after {
      display: none;
    }

    .ant-table-cell-scrollbar {
      box-shadow: none;
    }

    .ant-table-body {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #b7b7b9 !important;
        border-radius: 30px;
      }

      &::-webkit-scrollbar-track {
        background: #f5f6fb;
      }
    }

    .ant-table-thead > tr > th {
      background-color: #f5f6fb;
      color: #000000 !important;
      font-size: 12px !important;
      line-height: 18px;
      padding: 13px 13px 13px 0;

      &:before {
        //Hide lines between columns
        display: none;
      }
    }

    &_Flag {
      padding: 10px 12px 10px 24px;
      font-size: 18px !important;

      &.empty {
        color: $light-blue !important;
      }

      &.due {
        color: #000 !important;
      }

      &.overdue {
        color: $error !important;
      }
    }

    &_Checkbox {
      padding: 10px 11px 10px 23px;
    }

    &_NameAndAvatar {
      display: flex;
      align-items: center;
      gap: 12px;

      &--paddingLeft {
        padding-left: 32px;
      }

      &_Name {
        display: grid;

        &_FullName {
          word-break: break-word;
          white-space: break-spaces;
          font-weight: 600;
          color: #101828;
        }

        &_OneLiner {
          font-size: 11px;
          color: #8d8d8d;
          //white-space: nowrap;
          //width: inherit;
          //overflow: hidden;
          //text-overflow: ellipsis;
        }
      }
    }

    .strength,
    .importance {
      p {
        font-size: 14px;
        color: #646464;
      }
    }

    &_LastSent {
      span {
        font-size: 13px;
        color: #646464;
      }
    }

    &_Archive {
      margin-left: 10px;
      color: #646464;
    }

    &_Unarchive {
      margin-left: 4px;
      text-decoration: underline;
      font-style: italic;
      color: $silver-blue !important;
      transition: opacity 0.2s linear;

      &:hover {
        opacity: 0.7;
      }
    }

    &_Row {
      &:hover > td {
        background-color: #f9fafd !important;

        .PeopleTable_NameAndAvatar_Name_FullName {
          text-decoration: underline;
        }
      }

      td {
        cursor: pointer;
        background-color: #ffffff;
        padding: 5px 10px 5px 0;
      }
    }
  }
}

.SpheresColumn_CustomizeColumnButton_Tooltip {
  .ant-tooltip-inner {
    width: 180px !important;
  }
}
