@import '../../../variables.scss';

.EnrichmentsSection_Table {
  .ant-table tr {
    td {
      border: none;
      width: 30%;
    }

    td:nth-child(2) {
      width: 50%;
    }

    td:nth-child(3) {
      width: 20%;
    }
  }

  &_Enrichments {
    display: grid;
    color: #8D8D8D;

    &_Title {
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 5px;

      &_Icon {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }


    &_Details {
      &_Row {
        display: grid;
        grid-template-columns: 70px 1fr;
        align-items: center;
        gap: 10px;

        h4 {
          font-size: 15px;
          margin: 0;
          font-weight: bold;
          width: max-content;
        }
      }
    }
  }
}
