@import '../../variables.scss';


.StageSphere {
  margin: 10px 28px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 100%;

  &_TitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }

  &_AddStep {
    border: none;
    background-color: transparent;
    color: $lighter-gray;
    font-family: $inter-font;
    font-size: 24px;
    cursor: pointer;

    &:hover {
      color: $light-gray;
    }

  }


  &_Buttons {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;

    &_Red {
      font-size: 14px;
      font-family: $main-font;
      color: $strengths-weak !important;
      transition: color 200ms;

      &:hover, &:focus {
        color: $light-red !important;
        cursor: pointer;
      }
    }

    &_Green {
      cursor: pointer !important;
      border: 1.6px solid $light-green !important;
      background-color: $light-green !important;

      &:hover {
        background-color: $light-green !important;
      }
    }

  }


  &_Header {
    display: flex;
    flex-direction: column;
    background-color: $primary-white;
    margin-bottom: 20px !important;

    &_Title {
      font-family: $brandonGrotesque-font;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      color: $dark-blue !important;
    }
  }

  &_EditTitle {
    margin: 10px 0;
    display: flex;
    align-items: center;

    column-gap: 6px;

    &:hover > .StageSphere_EditIcon {
      color: $dark-blue;
    }
  }

  &_Title {
    font-size: 24pt;
    font-family: $main-font;
    font-weight: bold;
    color: $dark-blue;
    padding: 4px;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom: none !important;


  }

  &_EditIcon {
    color: transparent;
    font-size: 24pt;
    display: inline;
  }

  &_SubHeader {

    display: flex;
    justify-content: space-between;

    &_Text {
      margin-top: 10px;
      font-family: $inter-font;
      font-size: 14px;
      color: $dark-blue !important;
    }


  }

  &_BoardWrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-x: auto;

  }

  &_AddPanel {
    display: flex;
  }

  &_DraggableStepsWrapper {
    display: flex;
    flex-direction: row;
    height: 100%;

  }

  &_StepsContainer {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 30px;
    height: 100%;

    &::-webkit-scrollbar-thumb {
      background: $primary-black;
    }

    &::-webkit-scrollbar {
      height: 6px;
    }
  }

  &_StepWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    > span {
      text-align: center;
    }
  }

  &_DraggableStep {
    height: 100%;
    background-color: #ffffff;

  }

  &_Step {
    padding: 6px 12px;
    border-radius: 12px;
    border: 1px solid $lighter-gray;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    position: relative;

    &_Title {
      font-size: 12pt;
      font-family: $main-font;
      font-weight: bold;
      color: $dark-blue;
      padding: 0;
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      border-bottom: none !important;

      &:focus + .StageSphere_Step_EditIcon {
        display: none;
      }
    }

    &_Remove {
      text-align: right;
      display: flex;
      align-items: center;
      column-gap: 5px;

      svg {
        color: $light-red;
      }
    }

    &_EditIcon {
      font-size: 20px;
      display: inline;
      color: $dark-blue;
    }

    &_Content {
      min-width: 250px;
      min-height: 60%;
      overflow-y: auto;
      border-radius: 12px;
      flex: 1;
    }

    &_AddPerson {
      &_Select {
        width: 100%;
      }
    }

    &_Footer {
      position: sticky;
      bottom: 0;
    }

    &_NoPeople {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      align-items: center;
      justify-content: center;
    }

  }

  .isDragged {
    opacity: 0.8;
  }

  .isNotDragged {
    opacity: 1;
  }

  .draggingOver {
    background-color: $lighterer-gray;
  }

  .no-draggingOver {
    background-color: transparent;
  }


  &_PersonWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    border: 1px solid $lighter-gray;
    border-radius: 12px;
    justify-content: flex-start;
    padding: 8px 6px;
    margin: 10px 0;
    align-items: flex-start;
    background-color: $primary-white;

    &_LastContacted {
      font-family: $inter-font;
      font-size: 12px;
      color: $light-gray;
    }
  }

  .dragging {
    box-shadow: none;
  }

  .no-dragging {
    box-shadow: 0 7px 22px rgba(0, 0, 0, 0.04);
  }

  .no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }
}
