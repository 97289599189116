@import '../../../../../variables';

.ContactImageInput {
  position: relative;
  width: 100%;
  height: 183px;
  margin-bottom: 16px;

  &--enabled {
    cursor: url("../../../../Icons/CloudUploadOutline.png"), auto;
  }

  &--blank {
    height: 100px;
  }

  div {
    overflow: hidden;
    display: inline-block;
    width: 100%;
    max-height: 183px;
  }

  span {
    position: absolute;
    bottom: 5px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.75);
    padding: 1px 7px;
    border-radius: 5px;
    transition: all .3s linear;
    opacity: 0;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }

  .animate-avatar {
    animation: 500ms ease-out 0s 1 zoomIn forwards;
    animation-iteration-count: 1;
    opacity: 1;
    @keyframes zoomIn {

      0% {
        transform: scale(1.1);
      }
    }


  }

  img {
    width: 100%;
    border-radius: 7px;
    object-fit: cover;
    transition-property: top, opacity;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0, .48, .3, .97);
    position: relative;
    opacity: 0;

    &.profile_variation0, &.profile_variation1, &.profile_variation2, &.profile_variation3 {
      height: 100px;
    }

    &.profile_variation0 {
      background-color: $primary-gold;
    }

    &.profile_variation1 {
      background-color: $primary-off-white;
    }

    &.profile_variation2 {
      background-color: $primary-lila;
    }

    &.profile_variation3 {
      background-color: $primary-pink;
    }
  }
}
