@import "src/variables";

.Error {
  display: grid;
  grid-template-columns: 25% auto;
  justify-content: center;
  align-items: center;
  background-color: $dark-blue;
  height: 100vh;
  font-size: 20px;
  gap: 50px;

  @media (max-width: 1600px) {
    font-size: 16px;
  }

  @media (max-width: 1400px) {
    font-size: 14px;
  }

  @media (max-width: 1275px) {
    font-size: 12px;
  }

  @media (max-width: 1050px) {
    font-size: 10px;
  }

  @media (max-width: 900px) {
    font-size: 8px;
  }

  @media (max-width: 800px) {
    gap: 0;
    grid-template-columns: 1fr;
    align-content: center;

    &_Drawing {
      height: 200px;
    }

    &_Details {
      text-align: center;
    }
  }


  &_Drawing {
    max-width: 100%;
  }

  &_Details {
    &_Title {
      font-size: 5.5em;
      letter-spacing: 5px;
      color: $light-blue;
      font-weight: bold;
    }

    &_Description {
      font-family: "Lato Thin", sans-serif;
      font-size: 2em;
      color: #f8f8f8;
    }
  }
}