@import "../../../../../../variables";

.InteractionItem {
  border-radius: 6px;
  padding: 10px 12px;
  margin-bottom: 10px;
  background-color: $light-gray-filler;
  transition: linear 0.3s;

  .MessageDirection {
    &.sent {
      color: $lighterer-green;
    }

    &.received {
      color: $light-blue;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: #e8e8ea;

    .date-with-hover {
      .InteractionItem_TrashIcon_Container {
        display: inline-block;
        opacity: 1;
      }
      > :first-child {
        visibility: hidden;
        display: none;
      }
    }
  }

  &_Content {
    &_Header {
      display: grid;
      grid-template-columns: 20px auto;
      grid-gap: 10px;
      margin-bottom: 3px;

      &_Icon {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      &_SubjectAndDate {
        display: flex;
        justify-content: space-between;
        min-height: 25px;

        &_Date {
          display: flex;
          align-items: center;
          column-gap: 7px;
        }

        &--grey {
          color: $light-gray;
        }
      }
    }

    &_Body {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #909ca8;

      &_Icon {
        margin-left: 10px;
        font-size: 17px;
      }
    }
  }

  &_TrashIcon_Container {
    display: none;
    opacity: 0;
  }

  &_TrashIcon {
    svg {
      color: #909ca8;
    }
  }
}
