@import "../../../../../variables";

.HeaderTitlesContactDrawer {
  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }

  p {
    margin: 0;
    font-size: 13px;
    line-height: 16px;
    color: #8f8f8f;
  }

  &_FormItems {
    display: grid;
    grid-gap: 10px;
    grid-template-areas:
      "firstName lastName"
      "oneLiner oneLiner";

    .FormItem {
      &--first_name {
        grid-area: firstName;
      }

      &--last_name {
        grid-area: lastName;
      }

      &--one_liner {
        grid-area: oneLiner;
      }
    }
  }

  &_TitleWrapper {
    display: grid;
    grid-template-columns: 1fr 40px;
    grid-gap: 8px;
    align-items: center;

    &_Icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #ddf0fd;
    }
  }
}
