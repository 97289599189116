@import "../../../variables";

.SingleSphereCard {
  font-family: "Inter", sans-serif;
  text-align: center;
  background-color: #e4f3fe;
  border-radius: 6px;
  border: 2px solid #bde0fe;
  padding: 12px;
  margin: 16px 2px;

  button {
    background-color: transparent;
    border: none;
    color: black;

    &:hover {
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;
      opacity: 0.7;
    }
  }

  &_Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    h1 {
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      font-family: $albertSans-font;
      letter-spacing: -1px;
      color: $dark-text;
    }
  }

  &_Body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_Text {
      color: $dark-text;
      font-weight: 600;
      white-space: nowrap;
    }

    &_Frequency {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      align-items: center;
      width: 25%;

      &_Select {
        .ant-select-selector {
          border-radius: 8px !important;
        }

        .ant-select-selection-item {
          color: $dark-gray;
          text-align: left;
        }
      }
    }

    &_ShareButton {
      display: flex;
      column-gap: 10px;
      border: 1px solid #d0d5dd !important;
      border-radius: 8px;
      color: $dark-text !important;
      align-items: center;
      background-color: $white !important;
      padding: 4px 8px;
    }
  }
}
