.PresetTemplateModal {

  &_Form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin: 20px;

    &_item {
      display: flex;
      align-items: center;
      justify-content: left;
    }

    label {
      min-width: 100px;
      font-weight: bold;
    }



  }

  &_SubmitWrapper {


    button {
      height: 40px;
      width: 100%;
      font-weight: bold;
    }
  }

}
