@import '../../../variables.scss';

.ImportanceIndicator {
  display: flex;
  align-items: center;
  height: 31px;

  &_Bars {
    display: flex;
    cursor: pointer;
    align-items: flex-end;

    &-disabled {
      &:hover {
        cursor: not-allowed;
      }
    }

    &_Bar {
      &-1, &-2, &-3, &-4 {
        background-color: $lighter-gray;
        width: 4px;
        border-radius: 3px;
        margin-right: 3px;

        &.very_important {
          background-color: $ocean-blue;
        }
      }

      &-1, &-2, &-3 {
        &.important {
          background-color: $light-green;
        }
      }

      &-1, &-2 {
        &.medium {
          background-color: $light-orange;
        }
      }

      &-1 {
        height: 8px;

        &.not_important {
          background-color: $light-red;
        }
      }

      &-2 {
        height: 14px;
      }

      &-3 {
        height: 19px;
      }

      &-4 {
        height: 25px;
        margin-right: 7px;
      }
    }
  }

  p {
    color: $primary-black;
    font-family: $inter-font;
    font-weight: 400;
    font-size: 13px;
    margin: 0;
  }
}