@import '../../variables.scss';


.FlowSphere {
  padding-bottom: 50px !important;
  margin: 10px 28px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;


  &_TitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      border-radius: 8px;
      padding: 8px 14px;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      font-family: $inter-font;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

  }


  &_Buttons {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;

    &_Red {
      font-size: 14px;
      font-family: $main-font;
      color: $strengths-weak !important;
      transition: color 200ms;

      &:hover, &:focus {
        color: $light-red !important;
        cursor: pointer;
      }
    }

    &_Green {
      cursor: pointer !important;
      border: 1.6px solid $light-green !important;
      background-color: $light-green !important;
      color: $primary-white;

      &:hover {
        background-color: $light-green !important;
      }
    }

    &_Disabled {
      border: 1.6px solid $light-gray;
      background-color: $light-gray;
      cursor: not-allowed;
      color: $primary-white;

      &:hover {
        color: $primary-white;
        background-color: $light-gray;
      }
    }

    &_More {
      font-size: 20px;
      display: inline;
      color: $dark-blue;
    }

  }


  &_Header {
    display: flex;
    flex-direction: column;
    background-color: $primary-white;
    margin-bottom: 20px !important;

    &_Title {
      font-family: $brandonGrotesque-font;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      color: $dark-blue !important;
    }
  }

  &_EditTitle {
    margin: 8px 0;
    display: flex;
    align-items: center;

    &:hover > .FlowSphere_EditIcon {
      color: $dark-blue;
    }
  }

  &_Title {
    font-size: 24pt;
    font-family: $main-font;
    font-weight: bold;
    color: $dark-blue;
    padding: 4px 0;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom: none !important;
  }

  &_EditIcon {
    font-size: 24pt;
    color: transparent;
    transition: color 300ms;

  }

  &_SubHeader {

    display: flex;
    justify-content: space-between;

    &_Text {
      margin-top: 10px;
      font-family: $inter-font;
      font-size: 14px;
      color: $dark-blue !important;
    }


  }

  &_Steps {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &_StepWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    > span {
      text-align: center;
    }
  }

  .dragging {
    span {
      opacity: 0;
    }
  }

  .no-dragging {
    span {
      opacity: 1;
      transition: opacity 400ms;
    }
  }

  &_Step {
    padding: 24px 32px;
    border-radius: 12px;
    border: 1px solid $lighter-gray;
    box-shadow: 0 7px 22px rgba(0, 0, 0, 0.04);
    justify-content: space-around;
    align-content: center;
    display: flex;


  }

  .FlowStep {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
  }

  .FlowStep > span {
    display: flex;
    align-items: center;
  }

  &_StepContent {
    width: 80%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    font-family: $inter-font;

    .ant-select-lg {
      flex: 1;
      max-width: 40%;
    }
  }

  &_DoneStep {
    display: flex;
    row-gap: 20px;
    flex-direction: column;

    span {
      text-align: center;
    }
  }

  &_Footer {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    font-family: $inter-font;
    margin-top: 32px;

    button {
      cursor: pointer;
      border-radius: 8px;
      padding: 8px 14px;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border: 1.6px solid #1C9AFF;
      background-color: $primary-blue;
      color: $primary-white;
      font-family: $inter-font;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      &:hover {
        color: $primary-white;
        background-color: $primary-blue;
      }
    }
  }


}
