@import "../../../variables";

.TeamSection {
  position: relative;
  color: #252526;

  &_ListOfTeams {
    display: grid;
    grid-gap: 16px;
    margin-bottom: 32px;
  }

  .Subsection {
    border-radius: 16px;
    border: 1px solid #e4f3fe;
    background: #ffffff;
    padding: 16px 20px;

    h3 {
      font-family: $inter-font;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;
    }
  }

  .TeamDetails {
    .NewMemberButton {
      margin-top: 8px;
      height: 32px;
      padding: 0 24px;
      font-weight: 500;
      line-height: 20px;
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      color: #ffffff;
      background: #23cb99;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;
      }
    }

    .Button {
      border: none;
      cursor: pointer;
      border-radius: 8px;

      &--red {
        color: white;
        padding: 8px 24px;
        font-weight: 700;
        background-color: #f24726;
      }
    }

    &_Header {
      display: flex;
      gap: 16px;

      .RenameIcon {
        margin-left: -8px;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
          transition: opacity 0.2s ease-in-out;
        }
      }

      &_EditNameForm {
        display: flex;
        gap: 12px;
        height: 32px;

        button {
          margin: 0 !important;
        }
      }

      h3 {
        position: relative;
        font-size: 24px;
        padding-left: 16px;

        &:after {
          content: "";
          position: absolute;
          bottom: 14px;
          left: 0;
          width: 100%;
          height: 3px;
          background: #252526;
        }
      }

      p {
        white-space: pre-line;

        &::first-line {
          font-weight: 700;
        }
      }
    }

    &_Body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      p {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 6px;
      }

      ul {
        padding-left: 0;
      }

      &_TeamMembers {
        li {
          width: 360px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
          margin-bottom: 10px;

          .RemoveButton {
            color: red;
            font-size: 20px;

            &:hover {
              cursor: pointer;
              opacity: 0.7;
              transition: opacity 0.2s ease-in-out;
            }
          }
        }

        .bold_name {
          font-weight: 700;
        }

        .NewMemberInput {
          margin-top: 8px;
          height: 32px;
        }
      }

      &_Settings {
        label {
          margin-right: 8px;
        }

        li {
          display: flex;
          margin-bottom: 8px;
          color: #666666;
        }
      }
    }

    &_Footer {
      margin: 24px 8px 8px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .TeamInvitation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
      font-weight: 500;
    }

    .Button {
      height: 40px;
      width: 120px;
      line-height: 20px;
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      color: #ffffff;
      background: #23cb99;
      border: none;
      border-radius: 8px;
      margin-left: 16px;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;
      }

      &--red {
        color: white;
        background-color: #f24726;
      }
    }
  }

  .CreateTeam {
    &_Form {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 30px;

      .ant-form-item-explain-error {
        margin: 8px 0 0 8px;
      }

      &_SubmitButton {
        width: 160px;
        height: 46px;
        display: flex;
        padding: 8px 14px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1.6px solid #23cb99;
        font-family: $inter-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        color: #ffffff;
        background: #23cb99;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
          transition: opacity 0.2s ease-in-out;
        }
      }
    }
  }
}
