@import '../../../variables';

.DrawerOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  right: -22.5vw;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  color: white;
  font-weight: bold;
  font-size: 24px;
  transition: all 0.3s ease-in-out;

  & p {
    margin-top: 2.5rem;
  }

  &.merging, &.archiving, &.editing {
    right: 0;
  }

  &.merging {
    background-color: $connected;
  }

  &.archiving {
    background-color: $error;
  }

  &.editing {
    background-color: $light-blue;
  }
}