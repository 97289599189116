@import "../../../variables";

.LeftRail {
  font-family: $inter-font;
  height: 100vh;
  background: $light-blue !important;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 30px;
  }

  &::-webkit-scrollbar-track {
    background-color: $light-blue;
    margin-bottom: 88px;
  }

  button {
    text-align: left;
    padding: 0;
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
  }

  &_AddSphere {
    display: flex;

    &_Icon {
      margin: auto !important;
      display: flex !important;
      justify-content: center;
      align-items: center;

      span {
        margin: auto !important;
      }
    }
  }

  &_FeatureCard {
    background: #f2f9ff;
    border-radius: 8px;
    font-family: $inter-font;
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    padding: 14px 16px;
    margin: 14px 0 100px;

    &_Title {
      font-weight: bold;
      color: $primary-black;
      font-size: 14px;
    }

    &_Description {
      font-size: 12px;

      a {
        text-decoration: underline;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
          transition: opacity 0.2s ease-in-out;
        }
      }
    }

    &_Content {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
    }

    &_Image {
      height: 160px;
    }
  }

  &--ShowInfoBar {
    height: calc(100vh - 60px);
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  a {
    color: black;

    &:hover {
      color: black;
    }
  }

  &_Header {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px 10px 10px 0;
    height: 75px;

    &_Logo {
    }

    button {
      display: flex;
      align-items: center;
      border: none;
      background-color: transparent;
      padding: 10px 12px;
      border-radius: 6px;
      height: fit-content;

      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
      }
    }

    &-openButton {
      margin-left: 3px;
      transform: rotate(180deg);
    }

    &-closeButton {
      padding: 7px 9px !important;
      margin-left: -10px;
    }
  }

  &_List {
    flex: 1 1 auto;
    margin: 0 16px;

    &_Item {
      padding: 8px 12px;
      margin-bottom: 4px;
      border-radius: 6px;
      cursor: pointer;
      transition: background-color 0.4s ease-in-out;

      &:hover:not(&--active) {
        background-color: rgba(255, 255, 255, 0.25);
      }

      &--active {
        background-color: rgba(255, 255, 255, 0.9);
      }

      a,
      button {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        max-height: 32px;

        span:first-child {
          margin-right: 12px;
        }

        span:nth-child(2) {
          flex: 1 1 auto;
        }

        span:nth-child(3),
        span:nth-child(4) {
          margin: auto !important;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          background: #e4dbff;
          border-radius: 16px;
          font-weight: 500;

          &:hover {
            background: #dbd0fa;
            transition: background-color 0.2s ease-in-out;
          }
        }
      }
    }

    &-collapsed {
      margin: 0 5px;

      .LeftRail_List {
        &_Item {
          padding: 8px 10px;

          a {
            span:first-child {
              margin-right: 0;
            }

            span:nth-child(2) {
              display: none;
            }

            span:nth-child(3) {
              display: none;
            }
          }
        }
      }

      button.LeftRail_DatabaseLink {
        span:nth-child(2) {
          display: none !important;
        }
      }
    }
  }

  .MaxHeight200 {
    max-height: 200px !important;
  }

  &_Spheres {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    direction: rtl;
    word-break: break-word;
    margin: 8px 0 8px 15px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #eaecf0;
      border-radius: 30px;
    }

    &::-webkit-scrollbar-track {
      background-color: $light-blue;
    }

    &--ShowInfoBar {
      max-height: calc(40vh - 60px) !important;
    }

    &_Item {
      display: grid;
      grid-template-columns: auto 24px;
      grid-gap: 4px;
      align-items: center;
      padding: 6px 6px 6px 10px;
      margin-bottom: 4px;
      border-radius: 6px;
      margin-left: 6px;
      direction: ltr;
      cursor: pointer;
      transition: background-color 0.4s ease-in-out;

      &:hover:not(&--active) {
        background-color: rgba(255, 255, 255, 0.25);
      }

      &--active {
        background-color: rgba(255, 255, 255, 0.9);
      }

      &_EditIcon {
        color: transparent !important;
      }

      &:hover {
        .LeftRail_Spheres_Item_EditIcon {
          transition: all 0.4s ease-in-out;
          color: $primary-black !important;
        }
      }
    }
  }

  &_Settings {
    flex: 0 0 auto;
    margin: 0 16px 100px;

    .LeftRail_List_Item:first-child {
      display: flex;
      align-items: center;

      span:first-child {
        margin-right: 12px;
      }
    }

    &-collapsed {
      margin: 0 5px 10px;

      .LeftRail_List {
        &_Item {
          padding: 8px 10px;

          span:first-child {
            margin-right: 0 !important;
          }

          span:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }

  &_Footer {
    flex: 0 0 auto;
    display: flex;
    position: fixed;
    bottom: 0;
    padding: 24px 16px 24px;
    background-color: $light-blue;
    box-shadow: 0 -7px 22px rgba(0, 0, 0, 0.11);

    &_Name {
      display: grid;
      line-height: 20px;
      margin: 0 20px 0 12px;

      a {
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      span:nth-child(2) {
        width: 146px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 400;
        opacity: 0.5;
      }
    }

    &-collapsed {
      margin: 0 7px 24px;
      padding: 24px 0 0 0;
      border-top: 1px solid #eaecf0;
      box-shadow: none;
      position: unset;
    }
  }
}
