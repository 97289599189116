@import '../../../variables.scss';


.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  top: unset !important;
  bottom: 5vh;
  font-size: inherit;
  border-radius: 2px;
  width: fit-content;
  padding: 12px;
  border-color: $middle-blue;
  border-width: 1px;
  margin: 4px;
  text-align: center;
  position: fixed;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  color: $middle-blue;
  left: 10vw;
  z-index: 2;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  color: $white;
  background-color: $middle-blue;
  right: 10vw;
  z-index: 2;
}

.OrganizeContacts {

  &_ParticipantCard {
    display: flex !important;
    flex-direction: column;

    &_Text {
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 70px;
      white-space: nowrap;
    }

    &_Date {
      font-family: $main-font !important;
      color: $light-gray !important;
      font-size: 13px !important;
      font-weight: 400 !important;
    }

  }

  &_Card > div {
    padding: 12px;
  }

  &_Disabled {
    background-color: #E8E8E8 !important;
    color: #a3a3a1 !important;
    border-color: #a3a3a1 !important;
  }

  &_Spheres {
    display: flex;
    flex-direction: column;
    margin-bottom: 25%;
    max-height: 75vh;
    overflow-y: auto;

    .ant-tag-checkable-checked {
      color: $white !important;
      background-color: $middle-blue !important;
    }
  }

  &_Container {
    height: 100vh;
    margin: 3vh 2vh;
    text-align: left;
    overflow-x: scroll;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &_Header {
      margin-bottom: 36px;
    }

    h2 {
      margin-bottom: 0 !important;
    }
  }

  &_Container::-webkit-scrollbar {
    display: none;
  }

  &_ButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 12px 0;
    width: 100%;
    background-color: $whiter;
    box-shadow: 0px 2px 6px rgba($light-gray, .3);


    &_Next {
      border-radius: 2px;
      border-color: $middle-blue;
      border-width: 1px;
      color: $middle-blue;
    }
  }

  &_Button {
    border-radius: 2px;
    border-color: $middle-blue;
    border-width: 1px;
    color: $middle-blue;
    padding: 4px;
    margin: 4px;
    text-align: center;

    &_Dismiss {
      color: $error;
      width: 40%;
      margin: 12px auto;

      :hover {
        color: $error
      }
    }
    &_Skip {
      width: 40%;
      margin: 12px auto;
    }
  }

  &_LastSlide {
    margin: 36px 12px;
  }
}
