@import '../../../../variables';


.IntroductionsTab {
  height: 100%;

  //Introduction tabs nav
  .ant-tabs-nav {

    &:after {
      content: '';
      position: absolute;
      bottom: -21px;
      left: -15px;
      z-index: 1;
      width: calc(100% + 30px);
      height: 12px;
      background-color: white;
      filter: blur(2px);
    }

    &-list {
      justify-content: flex-start !important;
    }

  }

  .ant-list {
    height: calc(100% - 68px);
    padding: 0;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7b7b9 !important;
      border-radius: 30px;
    }

    &::-webkit-scrollbar-track {
      background: #F5F6FB;
      margin-bottom: 20px;
    }

    &-items {
      margin: 10px 0 18px;
    }
  }

  &_Item {
    position: relative;
    display: grid;
    grid-template-columns: 140px 30px 164px;
    justify-content: space-between;
    margin: 10px 16px;
    padding: 6px 0 15px;
    border-bottom: 1px solid #EAECF0;
    align-items: center;

    &:hover {
      cursor: pointer;

      .IntroductionsTab_Item_PersonTile_Status {
        transition: all .2s linear;

        &-awaiting {
          background: #d6e5ff;
          color: #1C9AFF;

          &:hover {
            background: #f3f8fd;
            color: #31a2fd;
          }
        }
      }

      .IntroductionsTab_Item_DeleteButton {
        transition: opacity .4s linear;
        opacity: 1;
      }
    }

    &_PersonTile {
      display: grid;
      grid-gap: 4px;

      &_AvatarAndName {
        display: flex;
        align-items: center;

        span:nth-child(2) {
          font-weight: 600;
          font-size: 11px;
          max-width: 125px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &_FullName {
          margin-left: 4px;
        }
      }

      &_Status {
        width: fit-content;
        border-radius: 6px;
        padding: 4px 6px;
        font-weight: 600;
        font-size: 11px;

        &-awaiting {
          background: #EFF8FF;
          color: #1C9AFF;
        }

        &-approved {
          background: rgba(64, 186, 44, 0.14);
          color: #40BA2C;
        }
      }

      &_Date {
        font-size: 10px;
      }
    }

    &_IconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: rgba(148, 152, 168, 0.15);

      svg {
        color: #9498A8;
        height: 18px;
        width: 18px;
      }
    }

    &_DeleteButton {
      cursor: pointer;
      position: absolute;
      opacity: 0;
      top: -6px;
      right: 0;

      svg {
        color: #ED3838;
        height: 18px;
        width: 18px;
      }
    }
  }

  &_Popup {
    max-width: 350px !important;

    .ant-tooltip-content {
      .ant-tooltip-inner {
        padding: 12px !important;
        background-color: #FFFFFF !important;
      }

      .ant-tooltip-arrow {
        display: none !important;
      }
    }

    &_Content {
      color: #000000;
      display: grid;
      grid-gap: 7px;
      word-break: break-word;

      &_Header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_Title {
          font-size: 12px;
          font-weight: 600;
        }

        &_ApproveButton, &_DeleteButton {
          width: fit-content;
          border: none;
          border-radius: 6px;
          font-size: 12px;
          font-weight: 600;
          padding: 3px 18px;

          &:hover {
            cursor: pointer;
          }
        }

        &_ApproveButton {
          background: #EFF8FF;
          color: #1C9AFF;

          &:hover {
            transition: all .2s linear;
            background: #d5e4ff;
            color: #1288e7;
          }
        }

        &_DeleteButton {
          background: #ffefef;
          color: #ED3838;

          &:hover {
            transition: all .2s linear;
            background: #fcdcdc;
            color: #d52a2a;
          }
        }
      }


      &_Body {
        font-size: 11px;
      }


    }
  }

  &_Pagination {
    display: flex;
    justify-content: center;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -15px;
      z-index: 1;
      width: 100%;
      height: 30px;
      background-color: white;
      filter: blur(3px);
    }
  }
}
