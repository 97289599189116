@import "../../variables.scss";

.DatabasePage {
  margin-top: 10px;

  &_WelcomeInfo {
    display: grid;
    grid-template-columns: 108px 1fr;
    grid-gap: 18px;
    justify-content: center;
    align-items: center;
    padding: 10px 15px 15px;
    margin-bottom: 10px;
    background-color: #e8f5ff;
    border-radius: 6px;

    h3 {
      font-family: $albertSans-font;
      letter-spacing: -1px;
      font-weight: 700;
      font-size: 26px;
      margin-bottom: 5px;
    }

    span {
      font-family: $inter-font;
      margin-top: 10px;
      font-size: 16px;
      color: #000000;

      button {
        text-decoration: underline;
        border: none;
        background-color: transparent;
        padding: 0;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
  }

  &_Header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;

    &_Search {
      margin-bottom: 20px;

      &--withCounter {
        margin-bottom: 0;
      }

      &_Counter {
        line-height: 20px;
        color: $placeholder-font-color;
        font-size: 13px;
        margin: 5px;
      }
    }

    &_Menu {
      display: flex;
      justify-content: flex-end;
      white-space: nowrap;
      gap: 24px;

      @media (max-width: 1700px) {
        gap: 10px;
      }

      @media (max-width: 1600px) {
        white-space: normal;
      }

      &_Item {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        color: #000000;
        transition: color 0.3s;

        &:hover {
          cursor: pointer;
          color: #40a9ff;
        }

        &_SpheralizerIcon {
          margin: 3px 1px 7px;
          height: 19px;
          width: 19px;
          border-radius: 4px;
          background: linear-gradient(-45deg, #ff82e8, #8dcbfe, #ffb8f2, #fbe8b9);
          background-size: 400% 400%;
          animation: gradient 15s ease infinite;
        }
      }
    }
  }

  &_ShareSpheresMenu {
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &_ExportCSVMenu {
    .ExportSpheresButton {
      position: sticky;
      display: block;
      top: 0;
      background: white;
      z-index: 2;
    }

    &_SpheresMenu {
      width: 15rem;
      height: 10rem;
      overflow-y: auto;
      background-color: #fff !important;
      border-radius: 6px !important;
      padding: 0 10px;

      &_Sphere {
        line-height: 1.5715 !important;
        font-family: $main-font;
        padding-bottom: 10px !important;
        padding-top: 10px !important;
        border-bottom: 1px solid rgba($light-gray, 0.4) !important;
        font-weight: bold;

        label {
          font-size: 14px !important;
          font-weight: 600 !important;
          color: $dark-blue !important;
          margin-right: 10px;
        }
      }
    }
  }
}
