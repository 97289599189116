@import "src/variables";

.ant-modal-mask {
  z-index: 100 !important;
}

.ant-modal-wrap {
  z-index: 100 !important;
}

.EmailComposerModal {
  z-index: 100 !important;
  position: absolute !important;
  max-width: unset !important;
  margin: unset !important;
  border-radius: 10px !important;

  .ant-modal-close-x {
    display: flex;

    svg {
      color: white;
      margin: auto;
    }
  }

  &_ConfirmModal {
    left: -200px;
    @media (max-width: 1760px) {
      left: -10vw;
    }
  }

  &_ConfirmButton {
    margin: 20px;
  }

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-header {
    border-radius: 0;
    background-color: $primary-black;
    color: $primary-white;
    padding: 16px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    .ant-modal-title h3 {
      color: $primary-white;
      font-family: $inter-font;
      font-size: 14px;
      margin: 0;
    }
  }

  &_Button {
    padding: 12px 4px;
    border-radius: 8px;
    color: $primary-blue;
    font-weight: bold;
  }

  &_Dropdown {
    overflow: scroll;
    max-height: 50vh;
  }

  &__button {
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-blue;
    border-radius: 0 0 10px 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &__text {
    &-button {
      font-weight: bold;
      font-size: 14px;
      color: $white;
    }

    &-mail {
      font-size: 14px;
      color: $dark-blue !important;

      &-type {
        color: $light-gray !important;
      }
    }
  }

  &_Container {
    display: flex;
    flex-direction: row;


  }

  &_Container > :first-child {
    flex: 1 0
  }

  &_SuggestedMessagesContainer {
    width: 300px;
    position: relative;
    overflow: hidden;
    background-color: $lighter-gray;

  }

  &_SuggestedMessages {

    margin-top: 20px;


    span {
      color: $dark-gray;
      font-size: 12px;
      text-align: center;
    }

    &_List {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      margin: 10px 10px 0 0;
    }

    &_Message {
      border-radius: 12px;
      border: 1px solid $lighter-gray;
      padding: 10px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: $lighterer-gray;
      }
    }
  }


  &_SuggestedMessagesDrawer {
    background-color: $lighterer-gray;

    position: absolute !important;
  }

}


.ant-input:focus {
  border: 1px rgba($light-gray, 0.2) solid !important;
}
