@import '../../variables.scss';

.DuplicatesAndEnrichmentsPage {
  padding-bottom: 50px !important;
  width: 100%;

  &_Back {
    padding: 0;
    margin: 20px 0;
  }

  .EnrichmentsSection, .DuplicatesSection {
    padding-bottom: 20px;

    &_Header {
      display: grid;
      grid-template-columns: 1fr 150px;
      margin: 10px;
      border-radius: 10px;
      background-color: $primary-white;
      margin-bottom: 20px !important;

      &_Title {
        font-family: $brandonGrotesque-font;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: $dark-blue !important;
      }

      &_Text {
        margin-top: 10px;
        font-family: $inter-font;
        font-size: 14px;
        color: $dark-blue !important;
      }
    }

    &_Table {
      padding-bottom: 10px;
      width: 100%;

      .ant-table tr {
        background-color: $primary-white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #EAECF0;
        margin-bottom: 10px;

        &:hover > td {
          background-color: transparent;
        }
      }

      .ant-table {
        .profile_variation0 {
          box-shadow: inset 4px 0 0 #1C9AFF;
        }

        .profile_variation1 {
          box-shadow: inset 4px 0 0 #F4C041;
        }

        .profile_variation2 {
          box-shadow: inset 4px 0 0 #FF71E4;
        }

      }

      &_Row {
        display: flex;
        gap: 10px;
      }


      &_FullName {
        font-family: $main-font !important;
        color: $dark-blue !important;
        font-size: 14px !important;
        font-weight: 600 !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
        cursor: pointer;
      }


      &_Email {
        font-family: $main-font !important;
        color: $light-gray !important;
        font-size: 12.5px !important;
        font-weight: 400 !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
      }

      &_PlusWrap {
        background: #E8F3FF;
        border-radius: 50%;
        padding: 4px;
        display: inline-flex;
      }

      &_DetailsWrap {
        justify-content: center;
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        font-family: $inter-font;
      }

      &_ButtonWrap {
        justify-content: space-around;
        display: flex;
        flex-direction: row;
        font-family: $inter-font;
      }

      &_ButtonMerge {
        cursor: pointer;
        border-radius: 8px;
        padding: 8px 14px;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border: 1.6px solid #1C9AFF;
        background-color: $primary-blue;
        color: $primary-white;
        font-family: $inter-font;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        &:hover {
          color: $primary-white;
          background-color: $primary-blue;
        }
      }

      &_ButtonNope {
        cursor: pointer;
        border-radius: 8px;
        padding: 8px 14px;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border: 1.6px solid #1C9AFF;
        color: $primary-blue;
        background-color: $primary-white;
        font-family: $inter-font;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }


    }
  }
}
