@import '../../../../../variables.scss';
@import '../../../../../scss/customAntd';
@import '../Tabs.style';

.CreateTask {
  @include Tab_Container;

  form {
    width: 100%;
  }

  &_DueDate {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
    margin-bottom: 8px;

    &--minified {
      margin: 0 5px 8px;
    }

    &_DateLabel {
      font-size: 12px !important;
      margin-left: 16px;
    }

    @include Tab_DateInput;
  }

  @include Tab_Textarea;

  @include my-ant-form-item-error;

  @include Tab_Footer;
}