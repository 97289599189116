@import "../../../variables";

.BasicSettings {
  .ant-form-item {
    margin: 0;
  }

  &_Form {
    &_LeaderboardInput,
    &_TimeZoneInput,
    &_PublicUsernameInput {
      display: grid;
      grid-template-columns: 145px 1fr;
      align-items: center;
      margin-bottom: 16px !important;
    }

    .ant-form-item-label > label {
      font-weight: normal;
    }

    &_PublicName {
      display: grid;
      grid-template-columns: 1fr 320px;
      grid-gap: 16px;
      align-items: center;
      font-family: $inter-font;
      text-align: left;
      color: #344054;
      font-size: 14px;
      line-height: 20px;

      input {
        padding-right: 42px !important;
      }

      &_Loading {
        position: absolute;
        top: 12px;
        right: 10px;
      }

      &_Available,
      &_NotAvailable {
        display: flex;
        align-items: center;
        gap: 6px;
        color: #039855;
        margin-bottom: 16px;
      }

      &_NotAvailable {
        color: #d92d20;
      }
    }

    .SubmitButton {
      margin-top: 20px !important;
    }
  }
}

.ButtonsSection {
  display: flex;
  gap: 11px;
  padding-top: 16px !important;

  &_Button {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1.6px solid black;
    font-family: $inter-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

    &:hover {
      cursor: pointer;
      opacity: 0.6;
      transition: opacity 0.2s ease-in-out;
    }

    &-White {
      background-color: #ffffff;
      color: #1c9aff;
      border-color: #1c9aff;
    }

    &-Red {
      background-color: #f04438;
      color: #ffffff;
      border-color: #f04438;
    }
  }
}
