.ChallengeTile {
  display: grid;
  grid-template-columns: 80px 1fr 32px;
  gap: 8px;
  align-items: center;
  background-color: #32d583;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 8px;
  margin: 10px 7px 0 0;

  &:hover {
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    opacity: 0.7;
  }

  &_Title,
  &_Title--marginBottom {
    color: #ffffff;
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 2px;
    line-height: normal;

    &--marginBottom {
      margin-bottom: 8px;
    }

    svg {
      margin-right: 5px;
    }
  }

  &_LearnMore {
    border: none;
    background-color: transparent;
    padding: 0;

    svg {
      width: 32px;
      height: 32px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
