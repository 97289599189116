@import "../../../../variables";

.SphereCreatorModal {
  text-align: left;

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    font-family: $inter-font;
    padding: 24px !important;
  }

  &_Title {
    font-size: 18px;
    font-weight: 500;
    color: #101828 !important;
  }

  &_Description {
    font-size: 14px;
    font-weight: 400;
    color: #667085 !important;
  }

  &_Recommendations {
    font-size: 14px;
    font-weight: 400;
    color: #667085 !important;

    button {
      border: none;
      background-color: transparent;
      text-align: left;
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      color: #017dee !important;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }

  &_Header {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &_Content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &_Link {
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    border-radius: 5px;
    border: 1px solid #909ca8;
    cursor: pointer;
    transition: border 0.2s ease-in-out;

    &:hover {
      border: 1px solid #101828;
    }
  }

  &_Footer {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    padding: 20px;
    color: #101828 !important;
    font-weight: 400;
    text-align: center;
    transition: border 0.2s ease-in-out;

    &:hover {
      border: 1px solid #101828;
    }
  }
}
