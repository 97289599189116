@import "../../../../../variables";

.ChallengeModal {
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
  }

  &_LoadingWrapper {
    width: 100%;
    height: 250px;
    display: grid;
    align-items: center;
    justify-content: center;
  }

  h1,
  h2 {
    font-family: $albertSans-font;
    letter-spacing: -1px;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 6px;
    text-align: center;
    color: #101828;
  }

  span {
    font-family: $inter-font;
    color: #344054;
    font-weight: 500;
  }

  &_CloseIcon {
    svg {
      color: black;
      height: 21px;
      width: 21px;

      &:hover {
        transition: opacity 0.2s ease-in-out;
        opacity: 0.7;
      }
    }
  }

  &_Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 20px 34px;

    span {
      width: 360px;
      text-align: center;
    }
  }

  &_CurvedBackground {
    margin-bottom: -1px;
  }

  &_Body {
    padding: 10px 50px;
    background-color: #e9f5ff;

    ul {
      padding: 0;

      li {
        margin-bottom: 12px;

        .ant-checkbox {
          margin-right: 10px;
        }

        .Progress {
          font-style: italic;
          margin-left: 2px;
        }
      }
    }

    &_AreYouIn {
      &:after,
      &:before {
        content: "";
        display: block;
        width: calc(100% + 30px);
        height: 1px;
        background-color: #d2dde6;
        margin: 25px -15px;
      }

      &_Wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 32px;

        button {
          color: white;
          background-color: #1c9aff;
          border: none;
          border-radius: 8px;
          box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
          padding: 8px 14px;
          font-weight: 500;
          font-size: 14px;
          font-family: $inter-font;

          &:hover {
            transition: opacity 0.2s ease-in-out;
            opacity: 0.7;
            cursor: pointer;
          }
        }
      }
    }

    &_Participant {
      display: grid;
      grid-template-columns: 40px auto 340px;
      align-items: center;
      gap: 14px;
      margin-bottom: 20px;

      &_Names {
        display: grid;
        word-break: break-word;

        span {
          &.ChallengeModal_Body_Participant_Names_FullName {
            font-weight: 600;
          }

          &.ChallengeModal_Body_Participant_Names_Bio {
            color: #808080;
            font-size: 12px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 110px;
          }
        }
      }
    }
  }
}
