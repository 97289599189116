@import "../../../../../variables";

.CallContactCard {
  display: grid;
  grid-template-columns: 60px 1fr 256px;
  align-items: center;
  grid-gap: 24px;
  padding: 12px 10px;
  width: 100%;
  transition: background-color 0.2s linear;

  &:hover {
    cursor: pointer;
    background-color: #f7f7f7;
  }

  &_AvatarBox {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
  }

  h4 {
    color: #5e5e5e;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }

  &_Spheres {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    align-items: center;
    margin-bottom: 4px;

    span {
      align-items: center;
      background-color: #9dd4ff;
      border-radius: 16px;
      height: 22px;
      text-align: center;
      padding: 2px 8px;
      font-size: 12px;
      color: #000000;
      max-width: 148px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  button.CallContactCard_Button {
    margin: 0 0 0 16px;
    width: 240px;
    font-size: 16px;

    svg {
      margin-left: 10px;
      width: 18px;
      height: 18px;
    }
  }
}
