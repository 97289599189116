@import "./../../../../variables.scss";

.ApiKeysModal {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  font-size: 16px;
  row-gap: 32px;
  font-family: $inter-font;
  min-height: 600px;

  &__modal {
    border-radius: 10px !important;

    .ant-modal-content {
      border-radius: 10px;
    }
  }

  &_Body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &_KeyTable {
      margin-bottom: 20px;
    }
  }

  &_Footer {
    margin-top: auto;
    width: 100%;

    &_AddNewKeyButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
    }

    .NewApiKeyForm {
      display: grid;
      grid-template-columns: 1fr 160px 160px;
      gap: 10px;
      height: 40px;

      input {
        height: 40px;
      }

      button {
        height: 40px;
      }
    }
  }

  .CopyToClipboardButton {
    position: relative;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
      transition: opacity 0.3s;
    }

    svg {
      position: absolute;
      top: -4px;
      left: 4px;
      font-size: 18px;
    }
  }
}
