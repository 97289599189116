@import '../../../../../variables';

#sectionReminders {
  margin-bottom: 20px;

  .SectionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    margin: 5px 0 15px;
  }

  .SectionReminders {

    &_Loading {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 20px;
      margin-top: 15px;
      color: #909CA8;

      span:nth-child(2) {
        font-size: 14px;
      }
    }

    &_Reminders {
      display: grid;
      grid-gap: 10px
    }

    &_LoadMore {
      display: block;
      color: #1C9AFF;
      margin: 0 0 20px 12px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
