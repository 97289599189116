@import "../../../variables";

.RecencySelector {
  min-width: 300px !important;
  padding: 10px !important;

  label {
    font-family: $main-font;
    font-size: 13px !important;
    color: $light-gray;
  }

  .ant-tooltip-content {
    .ant-tooltip-inner {
      padding: 10px !important;
      background-color: #fff !important;
    }

    .ant-tooltip-arrow {
      display: none !important;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &_Option {
    text-align: center;
    font-family: $main-font;
    color: $dark-gray;

    &:hover {
      background-color: rgba($light-blue, .1);
    }
  }
}