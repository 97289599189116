@import "../../../variables";

.RuleCreator {
  width: 380px !important;

  &_Block {
    width: 100%;
    padding: 1rem 0 1rem 0;

    &_Content {
      padding: 0.3rem 0 0.3rem 0;
      width: 100%;
    }

    &-actions {
      width: 100%;
      padding: 1rem 0 1rem 0;
    }
  }

  &_Sphere {
    .ant-input:focus {
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
    }

    &_Title {
      position: relative;
      font-size: 14pt;
      font-family: $main-font;
      font-weight: bold;
      color: $dark-blue;
      letter-spacing: 1px;
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
    }

    &_EditIcon {
      font-size: 14pt;
      color: $dark-blue;
      position: absolute;
      right: 0;
      top: 8px;
    }
  }

  &_Title {
    font-size: 12pt;
    font-family: $main-font;
    font-weight: bold;
    color: $dark-blue;
  }

  &_Select {
    width: 100% !important;

    &_Dropdown {
      z-index: 9999 !important;
    }
  }

  &_Description {
    font-size: 11pt;
    font-family: $main-font;
    color: $light-gray;
  }

  &_Research {
    &_Checkbox {

    }

    &_Description {
      font-size: 11pt;
      font-family: $main-font;
      color: $light-gray;
    }
  }

  &_SubmitButton {
    border-radius: 8px !important;
    width: 100% !important;
    background-color: $light-blue !important;
    font-size: 14px;
    font-weight: bold;
    color: $white !important;

    &:hover, &:focus {
      border-color: $light-blue !important;
    }
  }

  &_DeleteButton {
    font-size: 14px;
    font-family: $main-font;
    color: $strengths-weak !important;

    &:hover, &:focus {
      cursor: pointer;
    }
  }

  .ant-tooltip-content {
    .ant-tooltip-inner {
      padding: 15px !important;
      background-color: #fff !important;
    }

    .ant-tooltip-arrow {
      display: none !important;
    }
  }

  label {
    font-family: $main-font !important;
    font-size: 12pt !important;
    font-weight: bold !important;
    color: $dark-blue !important;
  }

  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $light-gray !important;
  }

  .ant-checkbox-checked::after {
    border: none !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $light-blue !important;
    border: none !important;
  }
}