@import "src/variables";

.EmailTemplates {

  &_Table {
    border: 1px solid #d3d3d3;
    margin-top: 16px !important;

    &_Text {
      color: $dark-blue !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ant-table-fixed {
      table-layout: fixed;
    }

    .ant-table-tbody > tr > td {
      word-wrap: break-word;
      word-break: break-all;
    }

    .ant-table-cell {
      overflow: hidden !important;
      text-overflow: ellipsis;

    }

    &_Body {
      color: $dark-blue !important;
      font-size: 14px !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }


    th {
      font-size: 10px !important;
      color: $light-gray !important;
    }

    td {
      font-size: 14px !important;
      color: $dark-blue !important;

      a {
        font-weight: 600 !important;

        &.connect {
          color: $light-blue !important;

          i {
            font-size: 13px !important;
            margin-right: 5px !important;
          }
        }

        &.remove {
          color: $dark-blue !important;

          i {
            font-size: 15px !important;
            margin-right: 5px !important;
          }
        }
      }
    }
  }


  &_LoadMore {
    border: none;
    background-color: transparent;
    color: $middle-blue;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 500;


    &:hover {
      cursor: pointer;
      opacity: 0.7;
      transition: 0.2s linear;
    }
  }

  &_Add {

    &:hover {
      cursor: pointer;
    }

    &-icon {
      font-size: 16px !important;
      color: $light-blue !important;
      margin-right: 5px !important;
    }

    &-text {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: $primary-black !important;
    }
  }


}
