@import "src/variables";

.BulkStrengthAndImportanceSelector {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 25px 20px;
  border-radius: 6px;
  background-color: $light-pink-filler;

  &_Strength, &_Importance {
    h3 {
      font-size: 14px;
      font-weight: 600;
      margin: 0 0 3px;
    }

    &_Level {
      margin-top: 12px;
    }
  }
}