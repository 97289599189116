@import "../../../variables";

.Reminders {
  display: grid;
  grid-gap: 12px;
  margin-top: 11px;

  &_Reminder {
    display: grid;
    grid-template-columns: 40px auto;
    grid-column-gap: 10px;
    align-items: center;
    padding: 10px 25px 10px 15px;
    border: 1px solid #eaecf0;
    box-shadow: 0 7px 22px rgba(0, 0, 0, 0.06);
    border-radius: 0 6px 6px 0;
    transition: border 0.2s linear;

    &--editing {
      border: 1px solid #808080;
    }

    &--type-from_trigger {
      border-left: 4px solid #fedf89 !important;
    }

    &--type-job_change {
      border-left: 4px solid #93cefe !important;
    }

    &--type-manually_created {
      border-left: 4px solid #494949 !important;
    }

    &:hover {
      border: 1px solid #808080;
    }

    &:hover .Reminders_Reminder_Body_Footer_Buttons {
      opacity: 1;
    }

    &_Body {
      &--large {
        display: grid;
        grid-template-columns: auto 320px;
        grid-gap: 10px;
      }

      &--small {
        display: grid;
        grid-template-columns: auto;
        grid-gap: 10px;
      }

      &_Content {
        color: #9a9a9a;
        font-size: 13px;
        line-height: 28px;
      }

      &_Footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        &_LastContact {
          font-size: 12px;
          color: $light-gray;

          svg {
            height: 14px;
            width: 14px;
          }
        }

        &_Buttons {
          width: fit-content;
          display: flex;
          gap: 5px;
          opacity: 0;
          transition: opacity 0.2s linear;

          button {
            display: flex;
            align-items: center;
            border: none;
            background-color: transparent;
            cursor: pointer;
            transition: color 0.2s linear;
          }

          button:first-child {
            color: #40ba2c;

            &:hover {
              color: #92d389;
            }

            svg {
              height: 24px;
              width: 24px;
              margin-right: 7px;
            }
          }

          button:nth-child(2) {
            color: #ed3838;

            &:hover {
              color: #e38a8a;
            }

            svg {
              height: 20px;
              width: 20px;
              margin-right: 7px;
            }
          }

          &_Cancel {
            color: #f4c041 !important;

            &:hover {
              color: #f4d78a !important;
            }
          }
        }

        &_EditedButtons {
          opacity: 1 !important;
        }
      }
    }
  }

  &_LoadingMore {
    display: flex;
    justify-content: center;

    button {
      border: none;
      background-color: transparent;
      color: #1c9afe;
      font-weight: 600;
      transition: all 0.2s linear;

      &:hover {
        cursor: pointer;
        color: #62b1fd;
      }
    }
  }
}
