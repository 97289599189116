@import '../../../variables.scss';

.CollectionSelector {
  width: 100%;
  border-bottom: 1px solid #D4DCE4;

  .ant-select-selector {
    margin-left: -8px !important;

    .ant-select-selection-placeholder {
      color: rgba(0, 0, 0, 0.54);
    }
  }


  &_Dropdown {
    padding: 0 !important;

    &_SubmitButton {
      background-color: $primary-blue;
      text-align: center;
      color: $primary-white;
      padding: 5px 0;
      font-weight: 600;

      &:hover {
        cursor: pointer;
      }
    }
  }


  &_ConfirmPopup {
    top: 30vh !important;
    right: calc(-50vw + 150px + 65px) !important;

    .ant-modal-content {
      border-radius: 10px;
      padding: 20px !important;

    }

    .ant-modal-body {
      text-align: center;

      p {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
      }
    }

    &_Buttons {
      display: flex;
      flex-direction: row;
      column-gap: 12px;
      width: 100%;
      padding-top: 20px;

      div {
        flex: 1;
        display: flex;
        text-align: center;
        cursor: pointer;
        padding: 8px;
        border-radius: 8px;
        font-size: 13px;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        transition: filter 200ms ease-in-out;

        &:hover {
          filter: brightness(1.15);
        }
      }
    }


    &_NoButton {
      border: 1px solid $light-gray;
      color: $dark-green-blue;
      background-color: transparent;

    }

    &_YesButton {
      background-color: $primary-blue;
      color: $white
    }
  }
}
