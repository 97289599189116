@import '../../../variables.scss';
@import "../../../scss/customAntd";

.FiltersDrawer {
  @include my-ant-drawer-header;
  font-family: $inter-font;

  &_Content {
    padding: 0 20px 20px;
  }
}