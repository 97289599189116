@import '../../../../../variables.scss';

.RangeDatePicker {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 11px;
  margin-top: 16px;

  &_Before, &_After {
    border-bottom: 1px solid #D4DCE4;

    p {
      font-size: 13px;
      font-weight: 500;
      margin: 0;
    }

    &_DatePicker {
      padding-left: 0;

      &::before {
        content: "";
        display: block;
        background-image: url("../../../../Icons/CalendarCheck.svg");
        background-repeat: no-repeat;
        width: 31px;
        height: 20px;
        margin-right: 11px;
      }

      .ant-picker-input > input::placeholder {
        color: $placeholder-font-color;
      }
    }
  }
}