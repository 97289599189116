@import '../../variables.scss';

.SuccessPayment, .FreeAccount {
  margin: 50px auto 10px;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 900px;
  justify-items: center;
  font-size: 18px;

  h1 {
    text-align: center;
    font-size: 82px;
    font-weight: bold;
    margin-bottom: 0;
  }

  span {
    max-width: 700px;
    width: 100%;
    text-align: justify;
    text-align-last: center;
    margin-bottom: 30px;
  }

  p {
    max-width: 700px;
    width: 100%;
    text-align: left;
  }

  img {
    max-width: 715px;
    width: 100%;
    margin-bottom: 18px;
  }

}