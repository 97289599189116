@import '../../../variables';

.LoginForm, .RegistrationForm, .ResetPasswordForm {
  max-width: 400px;

  .ant-form-item-extra {
    color: #667085;
  }

  @media (max-width: 400px) {
    max-width: 300px;

    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  &_InputLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin-bottom: 6px;

    @media (max-width: 400px) {
      font-size: 12px;
      margin-bottom: 3px;
    }

    &_Addon {
      color: $light-gray;
      font-size: 12px;

    }
  }

  &_Input {
    border-radius: 8px;
    height: 44px;
    font-size: 15px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

    @media (max-width: 400px) {
      height: 36px;
      font-size: 13px;
    }
  }

  &_SighUp, &_LogIn, &_ForgotPassword {
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    color: #667085;

    @media (max-width: 400px) {
      font-size: 12px;
    }
  }

  &_ForgotPassword, &_Link {
    font-weight: 500;
    cursor: pointer;
    color: #1C9AFF;

    &:hover {
      transition: opacity .2s linear;
      opacity: 0.6;
    }
  }

  &_Link {
    margin-left: 4px;
  }

  &_Button--Disabled, &_Button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0 16px;
    width: 100%;
    height: 44px;
    font-weight: 500;
    font-size: 16px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    transition: all .2s linear;

    @media (max-width: 400px) {
      height: 36px;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  &_Button {
    color: #FFFFFF;
    background: #1C9AFF;
    border: 1px solid #1C9AFF;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  &_Button--Provider {
    color: $dark-text;
    background: $whiter;
    border: 1px solid $light-gray;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
  }

    &_Button--Link {
        background: none;
        border: none;
        color: #1C9AFF;

    }

  &_Button--Disabled {
    color: #454545;
    background: #c4c4c4;
    border: 1px solid #c4c4c4;

    &:hover {
      cursor: not-allowed;
    }
  }

  &_AuthProvider {
    margin: 24px auto;

    &_DrawerContainer {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      justify-content: center;
      height: 100vh;
      width: 400px;
      margin: auto;

      &_InputWrapper {
        display: flex;
        flex-direction: column;
        row-gap: 6px;

        &_Description {
          border-radius: 12px;
            padding: 12px;
            background: #f5f5f5;
            color: #667085;
          border:  1px solid #f5f5f5;
          margin: 1rem 0;
        }
      }

    }

  }


}
