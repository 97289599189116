@import "../../../variables";

.UserPanelModal {
  .ant-modal-content {
    border-radius: 8px;
  }
}

.UserPanel {
  padding: 24px;

  a, h2 {
    color: #101828;
    font-family: $inter-font;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 6px;
  }

  p {
    color: #667085;
    font-family: $inter-font;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  button {
    color: #FFFFFF;
    font-family: $inter-font;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 8px;
    border: 1px solid #1C9AFF;
    background: #1C9AFF;
    box-shadow: 0 4px 4px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    width: 100%;
    padding: 10px 18px;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
      transition: ease-out opacity .3s;
    }
  }


  &_Card1, &_Card2, &_Card3 {
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #909CA8;
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    margin-bottom: 17px;

    h2 {
      margin-bottom: 10px;
    }

    .ant-select {
      width: 100%;

      .ant-select-selector {
        border-radius: 8px;
      }
    }

    &_Buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
  }

  &_Card1 {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &_QRCode {
      display: flex;
      justify-content: center;
    }

    h2, a {
      text-align: center;
    }
  }

  &_Card2{
    display: flex;
    flex-direction: column;
    row-gap: 14px;

    &_FlowWarning {
      color: $error;
      font-family: $inter-font;
      font-size: 12px;
      margin-bottom: 20px;
      display: flex;
      column-gap: 10px;
    }
  }




  &_Card3 {
    display: grid;

    a {
      display: flex;
      justify-content: center;
      width: 100%;
      color: #FFFFFF;
      font-family: $inter-font;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      border-radius: 8px;
      border: 1px solid #1C9AFF;
      background: #1C9AFF;
      box-shadow: 0 4px 4px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
      padding: 10px 18px;
      margin-bottom: 0;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: ease-out opacity .3s;
      }
    }
  }

  button.UserPanel_Close {
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFFFFF;
    color: #344054;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }
}
