@import "../../../../variables";

.EventCard {
  background-color: transparent !important;
  position: relative;
  display: grid !important;
  grid-template-columns: 290px 1fr 280px !important;
  margin-bottom: 12px;
  padding: 10px !important;
  border: 1px solid #eaecf0;
  box-shadow: 0 7px 22px rgba(0, 0, 0, 0.06);
  border-radius: 0 6px 6px 0;
  gap: 20px;

  .ant-list-item-meta-title {
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &_Title {
    position: relative;
    z-index: 2;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &_BackgroundAvatar {
    background-size: cover;
    background-position: center 30%;
    width: 310px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.1;

    &--color0 {
      background-color: rgba(128, 128, 128, 0.25) !important;
    }

    &--color1 {
      background-color: rgba(250, 78, 55, 0.25) !important;
    }

    &--color2 {
      background-color: rgba(250, 158, 20, 0.25) !important;
    }

    &--color3 {
      background-color: rgba(0, 171, 120, 0.25) !important;
    }

    &--color4 {
      background-color: rgba(0, 133, 255, 0.25) !important;
    }
  }

  &--type-birthday,
  &--type-special_date {
    border-left: 4px solid #ffe0f9 !important;
  }

  &--type-trigger {
    border-left: 4px solid #fedf89 !important;
  }

  &--type-job_change {
    border-left: 4px solid #fef3f2 !important;
  }

  &_Spheres {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    align-items: center;

    span {
      align-items: center;
      background-color: #e4f3fe;
      border-radius: 16px;
      height: 22px;
      text-align: center;
      padding: 2px 8px;
      font-size: 12px;
      color: #000000;
      max-width: 170px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .ant-list-item-action {
    button {
      border: none;
    }

    .EventCard_ApproveButton {
      background-color: #49abfd;
      border-radius: 8px;
      padding: 8px 14px;
      width: 112px;
      color: #ffffff;
      margin-right: 4px;

      &:hover {
        opacity: 0.7;
        cursor: pointer;
        transition: opacity 0.2s linear;
      }
    }

    .EventCard_DismissButton {
      background-color: transparent;
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;
      color: #a1aac8;
      transition: color 0.2s linear;

      &:hover {
        opacity: 0.7;
        cursor: pointer;
        transition: opacity 0.2s linear;
      }

      svg {
        margin-right: 6px;
        width: 13px;
        height: 13px;
      }
    }
  }

  &_Details {
    display: grid;
    gap: 4px;
    color: #949494;
    font-size: 13px;

    &_Tag {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      border-radius: 5px;
      color: #fff;
      font-family: $inter-font;
      font-size: 13px;
      font-weight: 600;
    }

    &_Tag--special_date,
    &_Tag--birthday {
      color: #ff71e5;
    }

    &_Tag--trigger {
      color: #fec84b;
    }

    &_Tag--job_change {
      color: #49abfd;
    }

    &--LastContact {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.2s linear;
      }
    }
  }
}
