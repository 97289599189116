@import "../../variables.scss";

.ant-btn {
  border-radius: 4px !important;
}

.Layout {
  position: relative;
  background-color: #f7fbff;

  &_Content {
    &_Body {
      padding: 10px 15px 25px;
      height: 100vh !important;
      overflow: auto !important;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #b7b7b9 !important;
        border-radius: 30px;
      }

      &::-webkit-scrollbar-track {
        background: #f5f6fb;
        margin: 2px 0;
      }

      &--ShowInfoBar {
        height: calc(100vh - 60px) !important;
      }
    }
  }
}

.LogoutOverlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $primary-blue;
  transform: translateY(-100%);
  z-index: 999999;
  opacity: 0;
  pointer-events: all;
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out,
    background-color 3s linear;

  &.active {
    transform: translateY(0);
    pointer-events: none;
    opacity: 1;
    background-color: $primary-blue;
  }

  span {
    color: $white!important;
    font-family: $inter-font!important;
    margin-top: 1rem!important;
    font-size: 1.1rem!important;
    opacity: 1;
    transition: all 0.6s ease-in-out;
  }
}
