@import "../../../variables";

.StartBuildingCard {
  font-family: "Inter", sans-serif;
  text-align: center;

  button {
    height: 44px;
    width: 110px;
    background-color: #1C9AFE;
    border: 1px solid #1C9AFE;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;
      opacity: 0.7;
    }
  }

  &_SpheresGroups {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
    padding: 24px 24px 35px 24px;

    .SpheresGroup {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid #909CA8;
      border-radius: 5px;
      padding: 20px 24px 15px 10px;

      &_Body {
        display: grid;

        h2 {
          font-size: 18px;
          font-weight: 600;
          text-align: left;
        }

        span {
          color: #797979;
          text-align: left;
        }
      }

      &_ButtonWrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
      }
    }
  }

  &_SpheresGroupDetails {
    margin: 20px;

    h2 {
      font-size: 18px;
      text-align: left;
    }

    .SphereDetails {
      display: grid;
      align-items: center;
      grid-template-columns: 30px auto 110px 140px;
      border: 1px solid #909CA8;
      border-radius: 5px;
      margin-bottom: 20px;

      padding: 10px;

      &_Name {
        font-size: 16px;
        text-align: left;
      }

      &_Frequency {
        color: #797979;
        text-align: left;
      }
    }

    &_Buttons {
      display: flex;
      justify-content: space-between;

      button:first-child {
        background-color: white;
        border: 1px solid black;
        color: black;
      }
    }
  }

  .SpheresGroup, .SphereDetails {
    &--variant0 {
      border-left: 10px solid #2E9DFC;
    }

    &--variant1 {
      border-left: 10px solid #F04438;
    }

    &--variant2 {
      border-left: 10px solid #FDB022;
    }

    &--variant3 {
      border-left: 10px solid #F408D5;
    }
  }
}