@import "src/variables";

.TinyCustomEditor {

  &_Wrapper {
    margin-top: 24px;
    min-width: 100%;
    max-width: 870px;

  }

  &_Extras {
    display: flex;
    padding: 8px;
    margin-top: 16px;
    background: #F7F7F7;
    border-radius: 10px;
    border: 1px solid #E7E7E7;
  }



}


.blot-formatter__toolbar-button {
  display: none !important;
}

.SignaturesEditor--ModeHTML {
  .blot-formatter__overlay {
    display: none !important;
  }
}

.blurred-editor div.ql-toolbar {
  display: none;
}

.ql-toolbar.ql-snow {
  background: #F7F7F7;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #E7E7E7;
  border-bottom: none;
}

.ql-container.ql-snow {
  border: 1px solid #E7E7E7;

}

.attachment-upload {
  margin-bottom: 12px;
}
