@import "src/variables";

.PageTemplate {
  position: fixed;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;

  &_Legal {
    position: absolute;
    bottom: 5px;
    right: 10px;
    text-align: right;
    color: #000000;

    @media (max-width: 1200px) {
      flex: 0 0 auto;
      color: #667085;
      position: static;
      padding: 0 5px;
    }

    a {
      color: #000000;
      font-size: 10px;

      &:hover {
        color: #565656;
      }

      @media (max-width: 1200px) {
        color: #667085;

        &:hover {
          color: #929bab;
        }
      }
    }
  }

  &_Content {
    display: flex;
    flex-direction: column;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    &_Form {
      flex: 1 1 auto;
      display: grid;
      align-items: center;
      justify-content: center;
      padding: 20px;
      font-family: $inter-font;

      &_Logo {
        height: 70px;
        margin-left: -16px;

        @media (max-width: 400px) {
          height: 58px;
        }
      }

      h1 {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #101828;
        margin-bottom: 12px;

        @media (max-width: 400px) {
          font-size: 30px;
          margin-bottom: 0;
        }
      }

      h3 {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: #667085;

        @media (max-width: 400px) {
          font-size: 12px;
        }
      }
    }

    &_Footer {
      flex: 0 0 auto;
      height: 40px;
      display: flex;
      width: 100%;
      justify-content: space-between;

      p, a {
        display: flex;
        align-items: flex-end;
        height: 30px;
        margin: 5px 10px;
        color: #667085;

        @media (max-width: 800px) {
          font-size: 11px;
        }

        svg {
          margin: 0 4px 3px 0;
          height: 17px;
          width: 17px;

          @media (max-width: 800px) {
            margin: 0 2px 1px 0;
          }
        }
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  &_BackgroundImage {
    @media (max-width: 1200px) {
      height: 0;
    }

    img {
      height: 100vh;

      @media (max-width: 1200px) {
        height: 0;
        display: none;
      }
    }
  }

  &_SharingWrapper {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;

    &_Header {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      &_Title {
        font-weight: bold;
      }

      &_Description {
        color: $dark-gray;
      }
    }
  }
}
