@import '../../../../../variables';

#sectionContact {
  .SectionTitle {
    font-size: 16px;
    line-height: 19px;
    margin: 5px 0 15px;
  }

  .SectionContact {
    background-color: $light-pink-filler;
    margin-bottom: 15px;

    &_Field {
      display: flex;
      align-items: flex-start;

      &_Icon {
        svg {
          width: 24px;
          height: 24px;
        }
      }

      &_Items {
        display: grid;
        width: 100%;
        grid-gap: 5px;
        margin-left: 5px;

        &_Item {
          display: flex;
          gap: 4px;

          &--address {
            display: grid;
            grid-gap: 4px;
          }

          &_EmailAddress {
            cursor: pointer;
            transition: linear .2s;

            &:hover {
              color: #1C9AFF;
            }
          }

          &_Name {
            color: #8A8A8A;
          }
        }

        &_EditableItem {
          position: relative;
          border: 1px solid #d9d9d9;
          background-color: rgba(255, 255, 255, 0.65);
          border-radius: 6px;
          padding: 8px;
          margin-bottom: 5px;

          &_Inputs {
            display: grid;
            grid-template-columns: auto 120px;
            grid-gap: 5px;
          }

          &_SelectInput {
       padding: 0;
            margin: 0;
          }

          &_DeleteButton {
            cursor: pointer;
            position: absolute;
            top: -10px;
            right: -14px;
            color: #1C9AFF;

            svg {
              height: 20px;
              width: 20px;
            }
          }
        }

        &_AddNewButton {
          display: flex;
          width: 100%;
          align-items: center;
          color: #1C9AFF;
          cursor: pointer;
          margin-bottom: 10px;
        }
      }
    }
  }
}
