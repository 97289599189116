@import "src/variables";

.BulkActionsButtonsGroup {
  display: grid;
  grid-gap: 10px;
  padding: 20px 15px;
  border-radius: 6px;
  margin-bottom: 16px;
  background-color: $light-gray-filler;

  .SplitButton_InfoIcon {
    svg {
      width: 19px;
      height: 19px;
      color: white;
      margin-left: 3px;
    }
  }

  &_Popover {
    width: 300px;

    &_Content {
      font-size: 14px;
      padding: 8px;
      text-align: center;
    }
  }

  .SplitLinkButton {
    border: none;
    background-color: transparent;
    justify-content: center;
    width: 100%;
    color: #1890ff;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
      transition: opacity 0.2s linear;
    }

    &--disabled {
      color: $light-gray;
      cursor: not-allowed;
      text-decoration: none;
    }
  }
}