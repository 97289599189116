@import "../../../../../variables.scss";
@import "../../../../../scss/customAntd";
@import "../../../DashboardForm/Tabs/Tabs.style";

@mixin input_styles {
  font-family: $inter-font;
  font-size: 12px !important;
  background-color: #f5f6fb !important;
  border: none !important;
  border-radius: 6px !important;
  box-shadow: none !important;
}

.InteractionTextarea {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;

  &_Header {
    margin: 6px 0 0 11px;
  }

  .ant-form-item {
    margin: 0;
  }

  &_Container {
    font-family: $inter-font;
    background-color: #f5f6fb;
    border-radius: 6px;
    padding: 6px 7px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 80%;

    &--minified {
      padding: 6px 5px;
      margin: 0 5px 5px;
      background-size: auto;
      border: 1px solid #d4dce4;
    }
  }

  .ant-mentions-focused {
    box-shadow: none !important;
  }

  &_Textarea {
    border: none !important;
    background-color: transparent !important;
    font-size: 12px !important;

    textarea {
      font-family: $inter-font !important;
      background-color: transparent !important;

      &::placeholder {
        color: $placeholder-font-color !important;
      }
    }
  }

  @include Tab_Footer;
}

.AddInteractionPopover {
  @include Tab_Popover;
}
