@import '../../../../../variables.scss';
@import '../../../../../scss/customAntd';

@mixin input_styles {
  font-family: $inter-font;
  font-size: 12px !important;
  background-color: #F5F6FB !important;
  border: none !important;
  border-radius: 6px !important;
  box-shadow: none !important;
}

.CreateIntroduction {
  font-family: $inter-font;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;

  form {
    width: 100%;

    .ant-input:focus {
      border: none !important;
    }
  }

  .ant-form-item {
    margin: 0;
  }

  &_InputForm {
    input {
      @include input_styles;
      padding: 6px 16px !important;
      margin: 0 !important;

      &::placeholder {
        color: $placeholder-font-color !important;
      }
    }

    @include my-ant-form-item-error;

    .ant-form-item-explain-error {
      bottom: 7px !important;
      right: 0;
    }
  }

  @include remove-outline-on-select;

  &_Body {
    display: grid;
    grid-template-columns: 1fr 100px 1fr;
    margin: 8px 0;

    &_Select {
      .ant-select-selector {
        @include input_styles;
        margin-top: 8px;
        padding: 0 16px !important;
        height: 32px !important;

        .ant-select-selection-search {
          left: 16px !important;

          input {
            height: 100% !important;
          }
        }

        .ant-select-selection-placeholder {
          color: $placeholder-font-color;
        }
      }
    }

    @include my-ant-form-item-error;

    .ant-form-item-explain-error {
      bottom: 7px !important;
      right: 0;
    }

    &_Textarea {
      textarea {
        @include input_styles;
        margin: 8px 0;
        padding: 10px 16px;
        background-image: url("../../../../../images/NotesBackground.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 80%;
        height: 120px !important;
        resize: none;

        &::placeholder {
          color: $placeholder-font-color !important;
        }
      }
    }

    &_Divider {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &_Footer {
    margin-top: 15px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary-blue;
      font-weight: 500;
      background-color: $primary-white;
      border: 1px solid $primary-blue;
      border-radius: 8px;
      padding: 0 14px;
      height: 40px;
      width: 100%;
      cursor: pointer;
    }

    &_Button {
      &_Icon {
        margin-left: 10px;
      }

      &--disabled, &--disabled button {
        color: $lighter-gray !important;
        border-color: $lighter-gray !important;
        cursor: not-allowed !important;
      }
    }
  }
}