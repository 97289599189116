@import '../../../../variables.scss';

.NoteItem {
  background-color: $light-green-filler;
  border-radius: 6px;
  padding: 18px 15px 14px;
  margin-bottom: 10px;


  &:hover .NoteItem_Footer_Actions {
    opacity: 1;
  }

  &_Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    color: #909CA8;
    font-size: 12px;

    &_Actions {
      opacity: 0;
      transition: opacity .2s linear;

      span:first-child {
        margin-right: 11px;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}