@import '../../../variables';

.RelatableLoader {
  height: 100%;

  span[role="note"] {
    font-family: $inter-font;
    color: $dark-blue;
    font-size: 0.8rem;
    font-weight: 600;
    text-align: center;
  }

  animation: loader-appear 0.2s ease-in-out;

  &_Mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba($color: $primary-off-white, $alpha: 0.7);
    z-index: 999;
    background: linear-gradient(to top, #0000, rgba($color: $primary-off-white, $alpha: 0.7), #0000),
    linear-gradient(to left, #0000, rgba($color: $primary-off-white, $alpha: 0.7), #0000);

  }

  &_Flex {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;
    height: 100%;
  }

  &_Icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    animation: spinner-animation 0.8s infinite linear;
  }



  &_Quote {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
  }

  @keyframes spinner-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes loader-appear {

    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }


}
