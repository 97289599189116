@import "../../../variables";

.MyInfoSection {
  position: relative;

  &_Avatar {
    text-align: center;
    position: absolute;
    top: 15px;
    right: 20px;
    width: fit-content;
    z-index: 1;
    cursor: pointer;

    &-Loading {
      position: absolute;
      top: 40%;
      left: 40%;
      z-index: 9999;
    }
  }

  &_Form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 20px;
    grid-template-areas:
      "first-name last-name"
      "email phone-number"
      "bio bio"
      "footer footer";

    &_FirstName {
      grid-area: first-name;
    }

    &_LastName {
      grid-area: last-name;
    }

    &_Email {
      grid-area: email;
    }

    &_PhoneNumber {
      grid-area: phone-number;

      .ant-input-group-addon {
        border-radius: 8px 0 0 8px !important;
      }

      .ant-input {
        border-radius: 0 8px 8px 0 !important;
      }
    }

    &_Bio {
      grid-area: bio;
    }

    &_Footer {
      grid-area: footer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ButtonWhite {
        background-color: #FFFFFF !important;
        color: #1C9AFF !important;
        border-color: #1C9AFF !important;
        margin-left: 0 !important;
      }

      &_SubmitWrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;

        .ant-form-item {
          margin-top: 10px;
          display: flex !important;
          align-items: center;

          .ant-form-item-explain {
            position: absolute;
            font-size: 12px;
            bottom: -23px;
            left: 16px;
          }
        }
      }
    }
  }
}

