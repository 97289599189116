@import '../../../../variables';

.WelcomeTab {
  height: 100% !important;
  overflow-y: scroll !important;
  margin-bottom: 1rem !important;
  padding: 0 12px !important;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b7b7b9 !important;
    border-radius: 30px;
  }

  &::-webkit-scrollbar-track {
    background: #F5F6FB;
    margin-bottom: 20px;
  }

}