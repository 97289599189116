@import '../../../../../variables';

.AccountNavSections {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 22px;

  a {
    text-align: center;
    font-weight: 500;
    color: #868686;
    padding: 10px 0;
    transition: all .4s linear;

    &:hover {
      color: #454545;
    }
  }

  a.active {
    color: #0E7ED8;
    background-color: rgba(28, 154, 255, 0.08);
  }


  &_ActiveLine {
    position: absolute;
    height: 2px;
    border: none;
    bottom: 0;
    margin: 0;
    background-color: #0E7ED8;
    transition: all .4s linear;
  }


  &--Basic{
    background-color: #0E7ED8;
  }

  &--Accounts {
    background-color: #0E7ED8;
  }

  &--Billing{
    background-color: #0E7ED8;
  }

  &--Templates {
    background-color: #0E7ED8;
  }

  &--Signatures {
    background-color: #0E7ED8;
  }
}
