@import "../../../../variables.scss";

.DuplicatedEmailAndPhoneNumbersModal {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  font-size: 16px;
  row-gap: 32px;
  font-family: $inter-font;

  &__modal {
    border-radius: 10px !important;

    .ant-modal-content {
      border-radius: 10px;
    }
  }

  &_Text {
    display: flex;
    flex-direction: column;
    row-gap: 9px;

    &_Title {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }

    &_Description {
      font-size: 16px;

      &_DuplicateItem {
        font-size: 14px;
        margin: 2px 0;

        &_FullName {
          font-weight: 500;
          color: #48a3f8ff;
          cursor: pointer;

          &:hover {
            opacity: 0.7;
            transition: opacity 200ms ease-in-out;
          }
        }
      }
    }
  }

  .MergeDisabled {
    background-color: $light-gray;
    color: $dark-gray;
    cursor: not-allowed;
  }

  &_Buttons {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;

    div[role="button"] {
      flex: 1;
      text-align: center;
      cursor: pointer;
      padding: 8px 16px;
      border-radius: 8px;
      font-weight: 500;
      transition: filter 200ms ease-in-out;

      &:hover {
        filter: brightness(1.15);
      }
    }
  }

  &_Options {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    width: 100%;
    align-items: flex-end;


  }

  &_NoButton {
    background-color: $primary-blue;
    color: $white;
  }

  &_YesButton {

    border: 1px solid $light-gray;
    color: $dark-green-blue;
    background-color: transparent;

  }
}
