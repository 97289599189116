@import "../../../variables";

.EventList {
  margin: 11px 0 35px;

  &_Filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .more_options {
      background-color: transparent;
      border: none;
      border-radius: 5px;
      padding: 0;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.2s linear;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &_Main {
      display: flex;
      align-items: stretch;
      margin-bottom: 20px;
      gap: 15px;
      font-size: 12px;
      font-family: $inter-font;

      button {
        display: flex;
        background-color: transparent;
        border: none;
        padding: 4px 6px;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
          transition: opacity 0.2s linear;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          width: 24px;
          height: 18px;
          border-radius: 4px;
          margin-right: 4px;
        }
      }

      .inactive {
        color: rgba(0, 0, 0, 0.5);

        span {
          background-color: #EEF2F6;
          font-weight: bold;
          color: #364151;
        }
      }

      .active {
        &--all,
        &--trigger,
        &--special_date,
        &--job_change {
          font-weight: bold;
          color: #364151;
          border-radius: 5px;
        }

        &--all {
          background: #bde0fe;

          span {
            background: #1c9afe;
          }
        }

        &--trigger {
          background: #fedf89;

          span {
            background: #fdf4e4;
          }
        }

        &--special_date {
          background: #FFE0F9;

          span {
            background-color: #FFF3FD;
          }
        }

        &--job_change {
          background: #fadddc;

          span {
            background: #f8f4f4;
          }
        }
      }
    }
  }

  &_LoadingMore {
    display: flex;
    justify-content: center;

    button {
      border: none;
      background-color: transparent;
      color: #1c9afe;
      font-weight: 600;
      transition: all 0.2s linear;

      &:hover {
        cursor: pointer;
        color: #62b1fd;
      }
    }
  }
}
