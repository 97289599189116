@import "src/variables";

.SplitButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 6px !important;
  height: 46px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: $primary-white !important;
  transition: all .2s linear;

  &--lightGreen {
    background-color: $light-green;
    font-weight: 700;

    &:hover {
      background-color: rgba($light-green, 0.6);
    }
  }

  &--lightRed {
    background-color: $lighter-red;

    &:hover {
      background-color: rgba($lighter-red, 0.6);
    }
  }

  &--blue {
    background-color: $primary-blue;

    &:hover {
      background-color: rgba($primary-blue, 0.6);
    }
  }

  &--disabled {
    background-color: $light-gray !important;
    cursor: not-allowed;
  }

  &--withLoadingBar {
    position: relative;
    z-index: 3;
    background-color: transparent;
  }

  &_Icon {
    margin: 0 10px;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &_LoadingBar--dark {
    position: absolute;
    top: 0;
    z-index: 2;
    background-color: $light-green;
    height: 46px;
    border-radius: 6px !important;
  }

  &_LoadingBar--light {
    position: absolute;
    top: 0;
    z-index: 1;
    background-color: #9FDAC8;
    height: 46px;
    width: 100%;
    border-radius: 6px !important;
  }
}