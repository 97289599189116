@import '../../../variables';

.SectionWithFill {
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 16px;

  &--lightGrey {
    background-color: $light-gray-filler;
  }

  &--lightYellow {
    background-color: $light-yellow-filler;
  }

  &--lightPink {
    background-color: $light-pink-filler;
  }

  &--lightGreen {
    background-color: $light-green-filler;
  }

  &--white {
    background-color: $primary-white;
    border: 1px solid #E5EBF0;
  }

  h3 {
    font-family: $recoleta-font;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }
}