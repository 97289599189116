@import '../../../variables.scss';

.StrengthIndicator {
  height: 31px;
  padding-bottom: 5px;
  cursor: pointer;

  &-disabled {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 5px;

    &:hover {
      cursor: not-allowed;
    }
  }

  p {
    color: $primary-black;
    font-family: $inter-font;
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 6px;
  }

  &_Bar {
    height: 4px;
    border-radius: 2px;

    &-na {
      width: 11px;
      background-color: $light-gray;
    }

    &-weak {
      width: 31px;
      background-color: $light-red;
    }

    &-average {
      width: 41px;
      background-color: $light-orange;
    }

    &-strong {
      width: 51px;
      background-color: $light-green;
    }

    &-very_strong {
      width: 61px;
      background-color: $ocean-blue;
    }
  }
}