@import "../../../../../variables.scss";

.GrowRelationships {
  padding: 10px;

  &_Body {
    h1 {
      font-weight: 500;
      font-size: 16px;
    }

    &_Actions {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 15px;

      .StayInTouch,
      .KeepOrganized,
      .RememberDetails {
        border-radius: 6px;
        padding: 10px 15px;

        h3 {
          font-family: $albertSans-font;
          letter-spacing: -1px;
          font-weight: 700;
          font-size: 16px;
          margin-bottom: 2px;
        }

        span {
          color: #4b5565;
          font-size: 12px;
        }
      }

      .StayInTouch {
        display: grid;
        grid-template-rows: auto 1fr;
        background-color: #e4f3fe;

        &_Loading {
          margin-top: 20px;
          display: flex;
          justify-content: center;

          span {
            font-size: 20px;
          }
        }

        &_Body {
          display: grid;
          grid-template-rows: 1fr auto;
        }

        p {
          color: #4b5565;
          font-size: 12px;
          font-weight: 400;
          margin: 12px 0 10px;
        }

        &_Bars {
          display: flex;
          gap: 5px;
          margin: 0 0 4px;

          &_GreenBar,
          &_YellowBar,
          &_GreyBar {
            height: 18px;
            border-radius: 4px;
          }

          &_GreenBar,
          &_YellowBar {
            &:hover {
              opacity: 0.6;
              transition: opacity 0.2s linear;
            }
          }

          &_GreenBar {
            background-color: #32d583;
            width: 20%;
          }

          &_YellowBar {
            background-color: #fedf89;
            width: 80%;
          }

          &_GreyBar {
            background-color: #808080;
            width: 100%;
          }
        }

        &_BarsTitle {
          display: flex;
          justify-content: space-between;

          a {
            color: #4b5565;
            font-size: 12px;

            &:hover {
              opacity: 0.6;
              transition: opacity 0.2s linear;
            }
          }
        }
      }

      .KeepOrganized {
        background-color: #ffe0f9;

        &_Body {
          display: flex;
          flex-direction: column;
          height: calc(100% - 36px);
          justify-content: space-between;
          align-items: center;

          a {
            background-color: #49abfd;
            border-radius: 8px;
            padding: 8px 14px;
            margin: 0 8px 0 auto;
            font-size: 14px;
            color: #f8fafc;
            font-weight: 500;

            &:hover {
              cursor: pointer;
              background-color: #76befa;
              transition: background-color 0.3s linear;
            }
          }
        }
      }

      .RememberDetails {
        background-color: #fef3f2;

        &_Loading {
          display: flex;
          justify-content: center;
          margin-top: 30px;

          span {
            font-size: 20px;
          }
        }

        a {
          color: #4b5565;
          text-decoration: underline;
        }

        &_Events {
          display: grid;
          row-gap: 10px;
          margin-top: 5px;
          height: 100%;
          overflow-y: auto;

          &_Event {
            display: grid;
            grid-template-columns: 50px auto 40px;
            align-items: center;

            .Details {
              color: #4b5565;
              font-size: 12px;
              margin: 0;
              width: 150px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            button {
              border: none;
              background-color: transparent;

              &:hover {
                opacity: 0.6;
                transition: opacity 0.2s linear;
                cursor: pointer;
              }

              svg {
                height: 24px;
                width: 24px;
              }
            }
          }
        }
      }
    }
  }
}
