@import '../../../../variables';

.MessagesTab {
  height: 100%;

  &_OpenRate {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin: 0 20px 7px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: -15px;
      left: -5px;
      z-index: 1;
      width: calc(100% + 10px);
      height: 12px;
      background-color: white;
      filter: blur(2px);
    }

    &_Last30, &_AllTime {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 12px 14px;
      border-radius: 8px;

      h3:first-child {
        font-family: $recoleta-font;
        font-weight: 700;
        font-size: 26px;
        color: #101828;
        margin: 0;
      }

      &_Description {
        display: grid;

        span:first-child {
          font-size: 15px;
          font-weight: 600;
        }

        span:nth-child(2) {
          font-size: 12px;
          opacity: 0.6;
        }
      }
    }

    &_Last30 {
      background-color: #EAF6FF;
    }

    &_AllTime {
      background-color: #F2F9F1;
    }
  }

  .ant-list-item {
    border-bottom: none !important;
  }

  .ant-list {
    height: calc(100% - 141px);
    padding: 0;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7b7b9 !important;
      border-radius: 30px;
    }

    &::-webkit-scrollbar-track {
      background: #F5F6FB;
      margin-bottom: 20px;
    }

    &-items {
      margin: 10px 0 18px;
    }
  }

  .ant-list-items > .MessagesTab_Item {
    padding: 0 20px !important;
  }

  .ant-list-items > .MessagesTab_Item ~ .MessagesTab_Item {
    padding: 17px 20px 0 !important;
  }

  &_Item {
    .ant-collapse {
      background-color: transparent;
      width: 100%;

      .ant-collapse-item {
        border: none;

        .ant-collapse-header {
          padding: 0 !important;
        }

        .ant-collapse-content {
          margin: 0 -20px;
        }

        .ant-collapse-content-box {
          padding: 0 !important;
          background: #F2F6FA;

          .MessagesTab_Item {
            padding: 0 0 15px !important;
          }
        }
      }
    }

    &_Header {
      display: flex;
      justify-content: space-between;
    }

    .ant-list-item-meta, &_Header_Meta {
      display: flex;
      align-items: center;

      &-avatar, &_Avatar {
        margin-right: 7px;
      }

      &-title, &_Title {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        margin: 2px 0;
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-description, &_Description {
        color: #000000;
        font-size: 11px;
        line-height: 13px;
        width: 280px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-small {
      .ant-list-item-meta {

        &-title {
          font-size: 10px;
          line-height: 12px;
          width: 200px;
        }

        &-description {
          font-size: 10px;
          line-height: 12px;
          min-height: 12px;
          width: 260px;
        }
      }
    }

    &_Details {
      display: flex;
      justify-content: space-between;

      &_People {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 10px;
        line-height: 12px;
        color: #5A5A5A;
        margin-left: 40px;

        span {
          max-width: 220px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &_More {
          background: #F2F6FA;
          color: #000000;
          border-radius: 3px;
          padding: 2px 4px;
        }
      }

      &_RateAndCaret {
        display: flex;
        gap: 2px;

        &_Rate {
          display: flex;
          align-items: center;
          font-size: 10px;
          line-height: 12px;
          background: #FFFAED;
          border-radius: 3px;
          padding: 2px 4px;
        }
      }
    }

    &_TrackingIcon {
      display: flex !important;
      justify-content: flex-end !important;
      align-items: center !important;

      svg {
        width: 18px;
        height: 18px;
      }

      .blue {
        color: #1C9AFF;
      }

      .gray {
        color: #667085;
      }

      .green {
        color: #40BA2C;
      }

      .red {
        color: #df2c14;
      }

      .yellow {
        color: $primary-gold;
      }

      &_UpdateSplitMessage {
        color: $primary-blue;

        &:hover {
          cursor: pointer;
          color: #50a6ff;
        }
      }

      &_ResendMessage {
        color: $primary-blue;

        &:hover {
          cursor: pointer;
          color: #50a6ff;
        }
      }
    }


    &_MessagesList {
      padding: 15px 27px 0 57px;

      li {
        position: relative;

        &:before {
          content: "";
          width: 5px;
          height: 5px;
          margin-right: 7px;
          border-radius: 50%;
          background-color: #BEC8D2;
        }

        &:after {
          content: "";
          top: 14px;
          left: 2px;
          position: absolute;
          width: 1px;
          height: 45px;
          background-color: #BEC8D2;
        }

        &:last-child {
          &:after {
            width: 0;
            height: 0;
          }
        }
      }
    }
  }

  &_Pagination {
    display: flex;
    justify-content: center;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -15px;
      z-index: 1;
      width: 100%;
      height: 30px;
      background-color: white;
      filter: blur(3px);
    }
  }
}
