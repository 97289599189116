@import '../../../../../variables.scss';
@import "../../../../../scss/customAntd";

.ListWithCheckbox {
  &:not(&--noScrolling) {
    max-height: 178px;
    overflow-y: scroll;
  }

  margin: 20px 2px 7px;
  padding-right: 10px;

  &_Item {
    @include my-ant-checkbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    &--na, &--weak, &--average, &--strong, &--mighty {
      span {
        font-weight: 500;
      }
    }

    &--weak span {
      color: #ED4444;
    }

    &--average span {
      color: #F4C041;
    }

    &--strong span {
      color: #40BA2C;
    }

    &--mighty span {
      color: #1C9AFF;
    }

    &_SettingsIcon {
      font-size: 20px;
      color: $primary-black;
    }

    &_FlagIcon {
      font-size: 19px;
      color: #ED4444;
    }
  }
}