@import '../../../variables.scss';

.DuplicatesSection_Table {

  .ant-table-content > table {
    border-spacing: 0 10px !important;
    border-collapse: collapse !important;
    line-height: 2;
  }

  .ant-table tr {
    td {
      border: none;
      width: 30%;
    }

    td:nth-child(2) {
      width: 10%;
    }

    td:nth-child(3) {
      width: 40%;
    }

    td:nth-child(4) {
      width: 20%;
    }
  }
}