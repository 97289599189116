@import './../../variables.scss';


.InfoBar {
  height: 60px;
  width: 100%;
  background-color: $primary-black;
  padding: 0 8px;
display: flex;
  position: relative;
  &_WelcomeText {
    text-align: center;
    font-family: $inter-font;
    color: white;
    font-size: 16px;
    margin: auto;
  }

  &_Buttons {
    right: 20px;

    position: absolute;
    float: right;
    transform: translate(0, 80%);
  }

  &_DaysLeft {
    display: inline;
    padding: 12px 8px 12px 12px;
    background-color: $dark-gray;
    color: $primary-white;
    border-radius: 6px;
    font-weight: bold;
  }

  &_AddButton {
    margin-left: 12px;
    border-radius: 6px;
    display: inline;
    font-weight: bold;
    cursor: pointer;
    padding: 6px 10px;
    color: $primary-black;
    background-color: $primary-blue;

    &:hover {
      background-color: $light-blue;
    }
  }

  &_PaymentLink {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
}
