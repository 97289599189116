@import "src/variables";

.MessageCreator_DefaultScreen {


  display: flex;
  flex-direction: column;
  row-gap: 28px;
  margin: 0 20px;

  h3, h4, h5 {
    font-family: $inter-font;
    font-weight: 600;
    color: $primary-black;
  }

  h4 {
    color: $dark-gray;
  }

  &_Container {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  &_Card {
    display: flex;
    justify-content: space-between;
    background: $light-blue-filler;
    border: 1px solid $lighter-gray;
    border-radius: 8px;
    padding: 10px 20px;
    align-items: center;
    color: $light-gray;
    transition: all 100ms linear;

    &:hover {
      cursor: pointer;
      border-color: $light-gray;
    }

    &_Icon {
      color: $light-gray;
    }

    &_Text {
      font-family: $inter-font;
      font-weight: 500;
      color: $primary-black;
    }
  }

  &_LoadMore {
    display: flex;
    justify-content: center;

    button {
      border: none;
      background-color: transparent;
      color: #1c9afe;
      font-weight: 600;
      transition: all 0.2s linear;

      &:hover {
        cursor: pointer;
        color: #62b1fd;
      }
    }
  }

}
