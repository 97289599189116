@import "../../../variables";

.PublicView {
  width: 100%;
  background-color: #f2f4fa;
  padding: 84px 20px 100px;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 580px) {
    padding: 20px 20px 100px;
  }

  h2 {
    font-family: $albertSans-font;
    letter-spacing: -1px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
  }

  a,
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    height: 44px;
    border-radius: 8px;
    border: 1px solid #1c9aff;
    background: #1c9aff;
    color: #ffffff;
    font-family: $inter-font;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 24px auto 0;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
      transition: ease-out opacity 0.3s;
    }
  }

  &_Card {
    margin: 0 0 29px;
    border-radius: 8px;
    border: 1px solid #bde0fe;
    background: #f8fafc;
    max-width: 500px;
    width: 100%;
    padding: 16px 60px 30px;

    @media (max-width: 580px) {
      padding: 16px 20px 30px;
    }

    &_ThanksWrapper {
      display: grid;
      justify-content: center;
      align-items: center;
    }
  }

  &_Form {
    .ant-form-item-control {
      margin-bottom: 10px;

      input {
        padding: 10px 14px;
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        background: #ffffff;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          &:disabled {
            -webkit-text-fill-color: rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
          }
        }
      }
    }

    &_Name {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 10px;
      grid-template-areas:
        "label label"
        "first last";

      @media (max-width: 580px) {
        grid-template-areas:
          "label label"
          "first first"
          "last last";
      }

      &_Label {
        grid-area: label;
        display: flex;
        align-items: center;
        height: 32px;
      }

      &_First {
        grid-area: first;
      }

      &_Last {
        grid-area: last;
      }
    }

    &_Email,
    &_Phone,
    &_Note {
      display: grid;

      .ant-form-item-label {
        text-align: left;
      }
    }

    &_SaveButton {
      width: 100%;
      gap: 10px;

      &:disabled {
        opacity: 0.5 !important;
        cursor: not-allowed !important;
      }
    }
  }

  img {
    margin: 0 auto;
  }
}
