@import "../../../../variables.scss";

.ErrorOverlay {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  padding: 0 20px;

  &_Header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

  }

  h1 {
    font-size: 2.5rem;
    font-weight: 500;
    color: $black-text;
  }

    p {
        font-size: 1rem;
        font-weight: 400;
        color: $dark-text;
    }
}