@import '../../../variables';

.PersonAvatarAndName {
  &:hover {
    transition: all .5s cubic-bezier(.17, .67, .83, .67);
    cursor: pointer;
  }

  &_Name {
    font-weight: bold;
    padding-right: 5px;
    color: #101828;

    &:hover {
      text-decoration: underline;
    }
  }
}