@import "../../../../src/variables";
.AccountSettings {
  &_AppsSection {
    &_Body {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      flex-direction: column;
      gap: 10px;
    }
  }
}

.AccountOption {
  &_Description {

    span {
      font-size: 14px;
      color: $light-gray;
    }
  }
}


