@import "./variables.scss";
@import "~antd/dist/antd.css";

@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:300i,400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css?family=Merriweather&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@600&display=swap");

@font-face {
  font-family: "Recoleta";
  src: url("fonts/Recoleta/RecoletaRegular/font.woff2") format("woff2"),
    url("fonts/Recoleta/RecoletaRegular/font.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Recoleta";
  src: url("fonts/Recoleta/RecoletaSemiBold/font.woff2") format("woff2"),
    url("fonts/Recoleta/RecoletaSemiBold/font.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "BrandonGrotesque";
  src: url("fonts/BrandonGrotesque/BrandonGrotesqueMedium/Brandon_med.otf")
    format("opentype");
  font-weight: 420;
  font-style: normal;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

.ant-modal-body {
  padding: 0 !important;
}

.colorWeak {
  filter: invert(80%);
}

.ant-layout {
  min-height: 100vh;
  background-color: #ffffff !important;
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  position: relative;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $light-blue;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-blue;
}

ul,
ol {
  list-style: none;
}

.ant-typography-link {
  color: #276df0 !important;
}

.ant-empty-normal {
  margin: 0 20px 0 20px !important;
}

.ant-form-item-extra {
  color: $white;
}

.Notification {
  &-error {
    background-color: $error !important;

    .ant-notification-notice-message {
      font-family: $main-font !important;
      font-weight: 600 !important;
      color: $white !important;
    }

    .ant-notification-notice-description {
      font-family: $main-font !important;
      font-weight: 400 !important;
      color: $white !important;
    }

    .ant-notification-notice-icon {
      color: $white !important;
    }
  }

  &-info {
    .ant-notification-notice-message {
      font-family: $main-font !important;
      font-weight: 600 !important;
    }

    .ant-notification-notice-description {
      font-family: $main-font !important;
      font-weight: 400 !important;
    }

    &.clickable {
      b {
        font-size: 12pt !important;
      }

      &:hover {
        cursor: pointer !important;
      }
    }
  }

  &-success {
    background-color: $connected !important;

    .ant-notification-notice-message {
      font-family: $main-font !important;
      font-weight: 600 !important;
      color: $white !important;
    }

    .ant-notification-notice-description {
      font-family: $main-font !important;
      font-weight: 400 !important;
      color: $white !important;
    }

    .ant-notification-notice-icon {
      color: $white !important;
    }

    &.clickable {
      b {
        font-size: 12pt !important;
      }

      &:hover {
        cursor: pointer !important;
      }
    }
  }
}

.ant-drawer-body {
  padding: 0 !important;
}
