@import "../../../../../variables";

#sectionDetails {
  background-color: $light-yellow-filler;
  margin: 15px 0;

  .SectionDetails {
    &_Field {
      display: flex;
      align-items: center;

      .ant-form-item:nth-of-type(1) {
        flex-grow: 1;

        .ant-picker {
          width: 100%;
        }
      }

      &_Birthday {
        display: grid;
        grid-template-columns: 230px 1fr;
        align-items: center;
        grid-gap: 12px;
      }

      &--collection {
        align-items: center;

        svg {
          width: 24px;
        }

        .CollectionSelector {
          margin-left: 3px;

          .ant-select-selection-item {
            background-color: white;
            border: 1px solid #d9d9d9;
          }
        }
      }

      &_Items {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        align-items: center;

        &--referred {
          width: 100%;
        }

        &_Item {
          display: flex;
          align-items: center;
          background-color: #e4f3fe;
          border-radius: 16px;
          height: 24px;
          text-align: center;
          padding: 2px 8px;
          font-weight: 500;
          font-size: 12px;

          &--contact,
          &--SourceContact {
            text-align: center;
            padding: 2px;
            font-weight: 500;
            font-size: 12px;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
              color: $dark-text;
            }
          }

          &--SourceContact {
            padding: 2px 8px;
          }

          &--contact {
            background-color: transparent;
          }
        }
      }
    }

    &_DetailsField {
      display: flex;
      align-items: flex-start;

      &_Items {
        display: grid;
        width: 100%;
        grid-gap: 5px;
        margin-left: 5px;

        &_Item {
          display: flex;
          flex-direction: column;

          span:first-child {
            font-weight: 600;
          }
        }

        &_EditableItem {
          position: relative;
          border: 1px solid #d9d9d9;
          background-color: rgba(255, 255, 255, 0.65);
          border-radius: 6px;
          padding: 8px;
          margin-bottom: 5px;

          &_Inputs {
            display: grid;
            grid-gap: 5px;

            &_YearCheckbox {
              margin-left: 12px;
            }
          }

          &_DeleteButton {
            cursor: pointer;
            position: absolute;
            top: -10px;
            right: -14px;
            color: #1c9aff;

            svg {
              height: 20px;
              width: 20px;
            }
          }
        }

        &_AddNewButton {
          display: flex;
          width: 100%;
          align-items: center;
          color: #1c9aff;
          cursor: pointer;
          margin-bottom: 10px;
        }
      }
    }
  }
}
