.ChallengeDetailsModal {

  &_Form {
    margin: 20px;

    .ant-form-item {
      display: flex;
      align-items: center;
      justify-content: left;
    }

    label {
      font-weight: bold;
    }

    &_Goals {
      &_Goal {
        margin-bottom: 10px;

        .ant-input-number {
          margin-left: 10px;
        }
      }
    }

    &_SubmitWrapper {
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
      }

      button {
        height: 40px;
        width: 150px;
        font-weight: bold;
      }
    }
  }

}
