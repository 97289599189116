@import "../../../variables";

.TrialExtensions {

  .ant-modal-content {
    border-radius: 12px !important;
  }

  &_CreateSessionButton {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    color: #0f79ce;

    &:hover {
      transition: color linear .2s;
      color: #4298de;
      cursor: pointer;
    }
  }

  &_Title {
    font-size: 26px;
    font-family: $recoleta-font;
    font-weight: bold;
    text-align: center;

    span {
      position: relative;
      z-index: 1;

      &::after {
        content: '';
        position: absolute;
        bottom: -0.125rem;
        left: -0.5rem;
        right: -0.5rem;
        height: 0.75rem;

        z-index: -1;

        // The SVG is added as an SVG background image
        background-image: url("./../../../images/CurvedUnderline.svg");
        background-repeat: no-repeat;

        // This allows the SVG to flex in size to fit
        // any length of word. If the word is short,
        // the SVG will be stretched vertically, if it
        // is long, the SVG will be stretched horizontally.
        // The jagged nature of this particular SVG works
        // with this transforming.
        background-size: cover;
      }
    }
  }


  &_Text {
    font-family: $inter-font;
    text-align: left;
  }

  &_Bold {
    font-weight: bold;
    font-size: 18px;
  }

  &_Content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

  }

  &_CurvedBackground {
    position: relative;
    width: 100%;
    background-image: -webkit-linear-gradient(bottom, #E8F5FF, #E8F5FF 20%, transparent 20%, transparent 100%)

  }

  &_Header {
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    column-gap: 10px;

  }

  &_TrialDaysLeft {
    border-radius: 4px;
    margin: 10px 0;
    background-color: #F9E8FF;
    font-weight: bold;
    color: $primary-black;
    padding: 5px 10px;

    span {
      text-decoration: underline;
      color: #C711B5;
    }

  }


  &_Body {
    background-color: #E8F5FF;
    padding: 30px 20px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

  }


  &_Extensions {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 270px;
  }

  &_ExtensionBlock {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    align-items: center;
    column-gap: 10px;
  }

  &_Completed {
    background-color: $light-gray !important;

    &:after {
      content: " 👍🏾";
    }
  }

  &_Days {
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    border-radius: 4px;
    padding: 4px 10px;
    background-color: $primary-blue;
    color: $primary-white;
    width: 100px;
  }

}
