@import "../../../../variables";


.ConfirmModal {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  font-size: 16px;
  row-gap: 32px;
  font-family: $inter-font;

  &__modal {
    border-radius: 10px !important;

    .ant-modal-content {
      border-radius: 10px;
    }
  }


  &_Body {
    width: 100%;

    .ant-form-item {
      display: grid;

      &-label {
        font-family: $inter-font;
        text-align: left;
        color: #344054;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
      }

      .ant-input {
        padding: 10px 14px;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        font-family: $inter-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  &_Buttons {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    width: 100%;

    div {
      flex: 1;
      text-align: center;
      cursor: pointer;
      padding: 8px 16px;
      border-radius: 8px;
      font-weight: 500;
      transition: filter 200ms ease-in-out;

      &:hover {
        filter: brightness(1.15);
      }
    }
  }


  &_NoButton {
    border: 1px solid $light-gray;
    color: $dark-green-blue;
    background-color: transparent;

  }

  &_YesButton {
    background-color: $primary-blue;
    color: $white
  }
}
