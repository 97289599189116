@import '../../../../../variables';

#sectionNotes {
  margin-bottom: 20px;

  .SectionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    margin: 5px 0 15px;
  }

  .SectionNotes {
    &_Loading {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 20px;
      margin-top: 15px;
      color: #909CA8;

      span:nth-child(2) {
        font-size: 14px;
      }
    }
  }
}