@import "../../../variables";

.FocusModeTab {
  display: flex;
  gap: 16px;
  align-items: center;
  border-radius: 6px;
  background: #e4f3fe;
  padding: 16px 18px;
  margin-bottom: 24px;

  svg {
    min-width: 116px;
    @media (max-width: 1300px) {
      display: none;
    }
  }

  &_Wrapper {
    align-self: stretch;

    h2 {
      font-family: $albertSans-font !important;
      letter-spacing: -1px;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
    }

    span {
      font-size: 13px;
    }
  }

  &_Button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    white-space: nowrap;
    padding: 16px 20px;
    border: none;
    background: #12b76a;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 11px;

    &:hover {
      cursor: pointer;
      color: #ffffff;
      opacity: 0.8;
      transition: opacity 0.2s linear;
    }
  }
}
