@import "../../../variables";

.MiniDashboardForm {
  margin-bottom: 16px;

  .ant-tabs-tab-disabled {
    .MiniDashboardForm_Tab_Button {
      background-color: #e4ebf2;
      color: #8f8f8f;
    }
  }

  &_Tab {
    &_Wrapper {
      .ant-tabs-nav-list {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
        width: 100%;
      }

      .ant-tabs-tab-btn {
        width: 100%;
      }
    }

    &_Button {
      background-color: $light-gray-filler;
      padding: 8px 12px;
      border-radius: 6px;
      display: flex;
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }

    &_Title {
      font-size: 14px;
    }
  }

  &-visible {
    border-radius: 6px;
    border: 1px solid #e5ebf0;
    padding: 8px;

    .ant-tabs-nav-list {
      display: flex !important;
      grid-gap: 0 !important;
      width: 100%;
      justify-content: space-between;

      .ant-tabs-tab {
        padding-top: 0;
        padding-right: 10px;
        margin: 0;
        color: #667085;

        &:hover {
          color: #40a9ff;
        }

        .ant-tabs-tab-btn {
          display: flex;
          align-items: center;

          span:nth-of-type(1) {
            margin: 0 5px 0 0;
          }
        }
      }

      .ant-tabs-tab:nth-of-type(2) {
        margin: 0 8px;
      }
    }
  }

  &-invisible {
    .ant-tabs-nav {
      margin: 0 !important;

      .ant-tabs-tab {
        padding: 0;
        margin: 0;
      }
    }

    .ant-tabs-top > .ant-tabs-nav::before {
      border: none;
    }
  }
}
