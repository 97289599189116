@import '../../../../../variables.scss';
@import '../../../../../scss/customAntd';
@import '../Tabs.style';

@mixin input_styles {
  font-family: $inter-font;
  font-size: 12px !important;
  background-color: #F5F6FB !important;
  border: none !important;
  border-radius: 6px !important;
  box-shadow: none !important;
}

.CreateInteraction {
  @include Tab_Container;

  form {
    width: 100%;
  }

  &_Subject {
    .ant-input:focus {
      border: none !important;
    }

    input {
      @include input_styles;
      padding: 6px 16px !important;
      margin: 0 !important;

      &::placeholder {
        color: $placeholder-font-color !important;
      }
    }

    &--minified {
      margin: 0 5px !important;

      .ant-input:focus {
        border: 1px solid #D4DCE4 !important;
      }

      input {
        border: 1px solid #D4DCE4 !important;
      }
    }

    @include my-ant-form-item-error;

    .ant-form-item-explain-error {
      bottom: 7px !important;
      right: 0;
    }

  }

  &_InputGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
    margin: 8px 0;

    &--minified {
      margin: 8px 5px;

      .CreateInteraction_InputGroup_Select {
        .ant-select-selector {
          border: 1px solid #D4DCE4 !important;
        }
      }
    }

    @include remove-outline-on-select;

    &_Select {
      .ant-select-selector {
        @include input_styles;
        padding: 0 16px !important;
        height: 32px !important;
      }
    }

    @include Tab_DateInput;
  }

  @include Tab_Textarea;

  @include my-ant-form-item-error;

  @include Tab_Footer;
}

.AddInteractionPopover {
  @include Tab_Popover;
}