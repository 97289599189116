$white: #f8f8f8;
$whiter: #ffffff;
$lightererer-gray: #e8e8e8;
$lightererer-red: rgb(255, 190, 185);

$dark-gray: #73746f;
$middle-blue: #1890ff;
$silver-blue: rgba(148, 183, 192, 0.33);
$dark-green-blue: #133c45;

$strengths-weak: #ff8080;
$strengths-average: #ffd08e;
$strengths-strong: #6fc7a4;
$strengths-very-strong: #41c7e1;

$error: #fa4e37;
$connected: #23cb99;

$main-font: "Lato", sans-serif;
$inter-font: "Inter", sans-serif;
$recoleta-font: "Recoleta", sans-serif;
$brandonGrotesque-font: "BrandonGrotesque", sans-serif;
$albertSans-font: "Albert Sans", serif;
$fredoka-font: "Fredoka", sans-serif;

$dark-blue: #012d37;

$primary-black: #000000;
$primary-gold: #f4c041;
$primary-off-white: #f4f4f4;
$primary-white: #ffffff;
$primary-lila: #e7bdde;
$primary-blue: #1c9afe;
$primary-red: #ff4500;
$primary-pink: #ff71e4;
$primary-green: #12b76a;
$primary-orange: #ffa500;

$light-green: #23cb99;
$light-orange: #ffd08e;
$light-red: #fa4e37;
$light-gray: #a3a3a1;
$light-blue: #93cefe;

$lighter-green: #46d2a7;
$lighter-red: #f95555;
$lighter-gray: #e0e0e0;

$lighterer-gray: #f8f8f8;
$lighterer-red: #fda29b;

$lighterer-green: #86eecf;
$ocean-blue: #41c7e1;

$lighterest-green: #d1fadf;

$light-gray-filler: #f2f6fa;
$light-gray-filler-darker: #e8e8ea;
$light-yellow-filler: #fffaed;
$light-pink-filler: #fff4fd;
$light-green-filler: #f2f9f1;
$light-blue-filler: #e1f6f9;
//d0f8fa
$placeholder-font-color: #7e7e7e;
$dark-text: #364152;
$black-text: #101828;
