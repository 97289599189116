@import "src/variables";

.NumberOfSelectedRecords {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 15px;

  &_Counter {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-black;
    color: $primary-white;
    font-weight: 600;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    &--regular {
      font-size: 12px;
    }

    &--small {
      font-size: 10px;
    }
  }
}