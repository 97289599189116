@import "../../../variables.scss";

.DashboardForm {
  background-color: transparent !important;
  margin-bottom: 29px !important;
  border: none !important;

  &_TabTitle {
    display: flex;
    justify-content: center;
    padding: 9px 10px;
  }

  .ant-card-head {
    padding: 0;
    min-height: 41px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 12px;
  }

  .ant-card-body {
    opacity: 1;
    max-height: 500px;
    transition: max-height 0.5s linear, opacity 0.2s 0.2s linear;
    padding: 9px;
    border: 1px solid #f0f0f0;
    border-radius: 0 0 6px 6px;
  }

  .ant-tabs-tab {
    color: #667085;
    padding: 3px 4px 15px !important;

    &:hover {
      color: #40a9ff;
    }

    .ant-tabs-tab-btn {
      display: flex;
      align-items: center;
      font-size: 13px;

      span {
        margin-right: 8px;
      }

      span:nth-child(2) {
        line-height: 24px;
      }
    }
  }

  &--collapsed {
    .DashboardForm_TabTitle {
      display: flex;
      justify-content: center;
      background: #f2f6fa;
      border-radius: 6px;

      &--growRelationships {
        background-color: #d1fadf !important;
      }
    }

    .ant-card-head,
    .ant-tabs-nav::before {
      border: none !important;
    }

    .ant-card-body {
      opacity: 0;
      max-height: 0;
      padding: 0;
      transition: max-height 0.5s linear, opacity 0.2s 0.1s linear,
        padding 1ms 0.5s linear;
    }
  }

  &_PlantIcon {
    margin-top: 2px;
    margin-right: 6px !important;
  }
}
