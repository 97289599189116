@import "../../../../src/variables";

.AccountsTable {
  &_Columns {
    &_Actions {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      span {
        transition: transform 400ms ease-out;
      }

      &:hover > span {
        transform: rotate(180deg);
      }
    }
  }

  &_Expanded {
    &_CalendarsLoading {
      display: grid;
      gap: 9px;
      margin: 0 10px 15px;

      &_Header {
        max-width: 90% !important;
        width: 500px !important;
        height: 20px !important;
        margin: 3px 0 2px !important;
      }

      &_Text {
        max-width: 90% !important;
        width: 250px !important;
        height: 14px !important;
        margin: 4px 5px 0 !important;
      }
    }

    &_Calendars {
      display: grid;
      margin: 0 10px 15px;

      &_List {
        display: grid;
        gap: 5px;
        margin-left: 5px;
      }
    }

    &_ManageButtons {
      display: flex;
      justify-content: space-between;

      &_Deleting {
        color: #1c9afe;
        font-weight: bold;
      }

      &_MarkedForDeletion {
        color: #848484;
        font-weight: bold;
      }
    }
  }
}

.AccountOption {
  margin-top: 16px;
  border-radius: 16px;
  border: 1px solid #e4f3fe;
  background: #ffffff;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-family: $inter-font;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
}
