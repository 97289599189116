@import "../../../variables";

.Avatar {
  text-transform: capitalize;

  &--color0 {
    background-color: rgba(128, 128, 128, 0.25) !important;
  }

  &--color1 {
    background-color: rgba(250, 78, 55, 0.25) !important;
  }

  &--color2 {
    background-color: rgba(250, 158, 20, 0.25) !important;
  }

  &--color3 {
    background-color: rgba(0, 171, 120, 0.25) !important;
  }

  &--color4 {
    background-color: rgba(0, 133, 255, 0.25) !important;
  }

  &--shape_square {
    border-radius: 5px !important;
  }

  &--string {
    left: 50%;
    top: 50%;
    color: black;
    transform: translate(-50%, -50%);
    font-weight: 600;
    font-family: $inter-font;
  }

  &--image {
    opacity: 0.3;
  }
}
