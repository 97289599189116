@import "../../variables";

.SharedCollection {
  position: fixed;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100vw;

  &_NotFoundWrapper {
    margin: auto 30px
  }

  &_Wrapper {
    overflow: auto;
    margin: 10px 30px;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  &_Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    row-gap: 5px;
  }

  &_Logo {
    height: 70px;
    margin-left: -16px;

    @media (max-width: 400px) {
      height: 58px;
    }
  }

  &_Title {
    font-size: 30px;
    font-family: $brandonGrotesque-font;
  }

  &_Owner {
    display: flex;
    column-gap: 10px;
    align-items: center;
    font-weight: bold;
  }

  &_AddButton {
    margin-top: 10px;
    color: $dark-green-blue;
    background-color: $primary-white;
    border-color: $dark-green-blue;

    &:hover, &:focus {
      background-color: $dark-green-blue;
      color: $primary-white;
      border-color: $dark-green-blue;
    }
  }


  &_IgnoreButton {
    margin-top: 10px;
    color: $lighter-red;
    background-color: $primary-white;
    border-color: $lighter-red;

    &:hover, &:focus {
      background-color: $lighter-red;
      color: $primary-white;
      border-color: $lighter-red;
    }
  }

  &_BackgroundImage {
    @media (max-width: 1000px) {
      height: 0;
    }

    img {
      height: 100vh;
      width: auto;
      @media (max-width: 1000px) {
        height: 0;
        display: none;
      }
    }
  }

}

.SharedTableWrapper {
  height: 65vh;

  &_Header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &_Search {
      width: 30% !important;
    }

    &_Add {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 5px;
      cursor: pointer;
    }
  }
}

.SharedTable {
  overflow-y: scroll;
  height: 100%;
}

.LastSection {

  &:last-of-type section {
    min-height: calc(100vh - 250px);
  }
}

.SharedContactDrawer {
  &_Alert {
    margin-bottom: 10px;
  }
}


