@import '../../../../variables.scss';

@mixin Tab_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;

  .ant-form-item {
    margin: 0;
  }

  &_Container {
    font-family: $inter-font;
    background-color: #F5F6FB;
    border-radius: 6px;
    padding: 6px 7px;
    background-image: url("../../../../images/NotesBackground.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 80%;

    &--minified {
      padding: 6px 5px;
      margin: 0 5px 5px;
      background-size: auto;
      border: 1px solid #D4DCE4;
    }
  }
}

@mixin Tab_DateInput {
  &_DateInput {
    .ant-picker {
      font-family: $inter-font;
      font-size: 12px !important;
      background-color: #F5F6FB !important;
      border: none !important;
      border-radius: 6px !important;
      box-shadow: none;
      height: 32px;
      width: 100%;

      input {
        font-size: 12px;
      }
    }

    &--minified {
      .ant-picker {
        border: 1px solid #D4DCE4 !important;
      }
    }
  }
}

@mixin Tab_Textarea {
  .ant-mentions-focused {
    box-shadow: none !important;
  }

  &_Textarea {
    border: none !important;
    background-color: transparent !important;
    font-size: 12px !important;


    textarea {
      font-family: $inter-font !important;
      background-color: transparent !important;

      &::placeholder {
        color: $placeholder-font-color !important;
      }
    }
  }
}

@mixin Tab_Footer {
  &_Footer {
    margin: 10px 16px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--minified {
      margin: 10px 11px 4px;
    }

    &_Helper {
      font-size: 12px;
      color: $placeholder-font-color;
      margin-right: 20px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $inter-font;
      color: $primary-blue;
      font-weight: 500;
      background-color: $primary-white;
      border: 1px solid $primary-blue;
      border-radius: 8px;
      padding: 0 14px;
      height: 40px;
      cursor: pointer;
    }

    button.Footer_Button--minified {
      height: 34px !important;
      padding: 0 10px !important;
    }

    .Footer_Button--minified button {
      height: 34px !important;
      padding: 0 10px !important;
    }

    &_Button {

      &_Icon {
        margin-left: 10px;
      }

      &--disabled, &--disabled button {
        color: $lighter-gray !important;
        border-color: $lighter-gray !important;
        cursor: not-allowed !important;
      }
    }
  }
}

@mixin Tab_Popover {
  width: 300px;

  &_Content {
    font-size: 12px;
    color: $placeholder-font-color;
    text-align: justify;
    margin: 0;
    padding: 10px;
  }
}