@import "../../variables";

.PartnerPage {
  width: 100%;
  margin-bottom: 20px;

  h1 {
    margin: 8px 0 20px 20px;
    font-family: $albertSans-font;
    letter-spacing: -1px;
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
  }

  .PartnerForm,
  .Section {
    background-color: #f8fafc;
    border-radius: 16px;
    border: 1px solid #e4f3fe;
    padding: 10px 20px 16px;
    margin-bottom: 26px;

    h2 {
      font-family: $albertSans-font;
      letter-spacing: -1px;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }

    h4 {
      font-family: $inter-font;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }

    &_Item {
      margin-bottom: 16px;
    }

    .ant-upload.ant-upload-select-picture-card {
      width: 100%;
    }

    textarea {
      height: 400px;
    }

    &_UploadButton {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px 32px;
      width: 100%;
      border: dotted 1px #1c9aff;
      color: #1c9aff;
      background-color: #ffffff;

      &:hover {
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;
      }
    }

    .ant-form-item {
      display: grid;
      margin-bottom: 16px;

      &-label {
        font-family: $inter-font;
        text-align: left;
        color: #344054;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
      }

      .ant-input,
      .ant-select-selector {
        padding: 10px 14px;
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        font-family: $inter-font;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }

      .ant-select-selector,
      .ant-select-selector input {
        display: flex;
        align-items: center;
        height: 36px !important;
      }
    }

    &_Video {
      position: relative;
      border: 1px solid #d9d9d9;
      background-color: rgba(255, 255, 255, 0.65);
      border-radius: 6px;
      padding: 8px;
      margin-bottom: 20px;

      &_DeleteButton {
        position: absolute;
        top: -11px;
        right: -11px;
        color: #1c9aff;
        cursor: pointer;

        svg {
          height: 24px;
          width: 24px;
        }

        &:hover {
          opacity: 0.7;
          transition: opacity 0.2s ease-in-out;
        }
      }

      &_AddNewButton {
        display: flex;
        gap: 6px;
        width: 100%;
        align-items: center;
        color: #1c9aff;
        cursor: pointer;
        margin-bottom: 10px;

        &:hover {
          opacity: 0.7;
          transition: opacity 0.2s ease-in-out;
        }
      }
    }
  }

  .SubmitButton {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1.6px solid #23cb99;
    font-family: $inter-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    color: #ffffff;
    background: #23cb99;
    margin-left: auto;
    margin-top: 10px;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
      transition: opacity 0.2s ease-in-out;
    }
  }

  .SubmitButton-disabled {
    border: 1.6px solid #818181;
    color: #e0e0e0;
    background: #818181;

    &:hover {
      opacity: 1;
      cursor: not-allowed;
    }
  }
}
