@import "src/variables";

.SignaturesEditor {
  .tox-tinymce {
    border-radius: 10px 10px 0 0;
  }

  &_BodyEditor {
    margin-top: 10px;
    align-items: center;

    &_LinkText, &_LinkHTML {
      position: absolute;
      color: $primary-blue;
      font-weight: bold;
      z-index: 100;
    }

    &_LinkText {
      top: 45px;
      right: 0;
    }

    &_LinkHTML {
      top: 5px;
      right: 0;
    }

    .ql-editor {
      padding-right: 70px;
    }
  }

  &_SubmitButton {
    margin-top: -1px;
    width: 100%;
    border-radius: 0 0 5px 5px !important;
  }
}

@media (max-width: 1047px) and (min-width: 992px) {
  .SignaturesEditor {
    &_BodyEditor {
      &_LinkText {
        top: 70px;
        right: 0;
      }
    }
  }
}

@media (max-width: 898px) {
  .SignaturesEditor {
    &_BodyEditor {
      &_LinkText {
        top: 70px;
        right: 0;
      }
    }
  }
}
