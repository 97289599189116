@import "src/variables";

.ant-modal-mask {
  z-index: 89 !important;
}

.ant-modal-wrap {
  z-index: 89 !important;
}

.MessageForm {
  border-radius: 10px !important;
  padding: 20px;

  &_Link {
    font-weight: bold;
    display: flex;
    align-items: center;
    column-gap: 4px;

    svg {
      width: 16px;
      height: 16px;
    }

    button {
      background-color: transparent;
      border: none;
      color: #0e7ed8;
      font-weight: bold;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;
      }

      &:disabled {
        opacity: 0.5 !important;
        cursor: not-allowed !important;
      }
    }

    &_Related {
      display: flex !important;
      align-items: center;
      column-gap: 4px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &_FormWrapper {
    color: $dark-gray;

    input:focus {
      outline: none;
      border: none !important;
    }

    input,
    .ant-select-selector {
      padding: 0 !important;
      color: $primary-black;
    }

    .ant-select-selection-placeholder,
    .ant-form-item-control-input-content input {
      font-weight: bold;
    }

    .ant-divider {
      margin: 12px 0;
    }

    .ant-form-item {
      margin: 0;
    }
  }

  &_Dropdown {
    overflow: scroll;
    max-height: 50vh;
  }

  &_AIPopover {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 352px;
    padding: 12px;
  }

  &_Info {
    background-color: $primary-gold;
    padding: 6px;
    border-radius: 8px;

    a {
      color: $primary-black;
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        color: $primary-black;
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;
      }
    }
  }

  &__ToAndCC {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    &__text {
      &-button {
        font-weight: bold;
        font-size: 14px;
        color: $white;
      }

      &-mail {
        font-size: 14px;
        color: $dark-blue !important;

        &-type {
          color: $light-gray !important;
        }
      }
    }
  }
}

.InvalidSelect {
  background-color: $light-red;
}

.TemplateCol {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 12px;
}

.ant-input:focus {
  border: 1px rgba($light-gray, 0.2) solid !important;
}
