@import "../../variables.scss";

.Dashboard {
  margin: 0 15px 0 5px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgba(0, 0, 0, 0.7);
  }

  &_Header {
    position: relative;


    h1 {
      font-family: $albertSans-font !important;
      letter-spacing: -1px;
      font-weight: 700;
      font-size: 37px;
      padding-top: 10px;
    }

    &::before {
      content: "";
      background-image: url("./../../images/PeopleInCircles.svg");
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
      width: 253px;
      height: 87px;
      @media (max-width: 1300px) {
        z-index: -1;
      }
    }


    &_AccountWarning {
      flex-direction: row;
      margin: 10px 0 30px 0;
      padding: 10px 15px;
      background-color: $lightererer-red;
      border: 1px solid $lighterer-red;
      border-radius: 6px;
      color: $dark-text;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      column-gap: 6px;

      a {
        cursor: pointer;
        color: $primary-blue;
      }


    }

    .Linkedin {
      background-color: #f3f3f3!important;
      border-color: #f3f3f3!important;
    }
  }

  &_Cards {
    &_HeaderContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &_Header {
      display: block;
      font-family: $albertSans-font;
      letter-spacing: -1px;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;

      &_Buttons {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
      }

      &_SendMessageButton, &_SendMessageButton--disabled {
        border: none;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
        background-color: #49abfd;
        border-radius: 8px;
        padding: 8px 14px;
        color: #ffffff;
        margin-right: 4px;

        &:hover {
          opacity: 0.7;
          cursor: pointer;
          transition: opacity 0.2s linear;
        }


        &--disabled {
          button {
            display: flex;
            font-weight: bold;
            align-items: center;
            justify-content: center;
          }

          background-color: #e4ebf2;
          color: #8f8f8f;

          &:hover {
            cursor: not-allowed;
          }
        }
      }

      &_ShowMoreButton {
        border: none;
        color: #0e7ed8;
        background-color: transparent;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
          transition: opacity 0.2s ease-in-out;
        }
      }
    }

    &_Loading {
      display: grid;
      justify-content: center;
      margin: 100px 0;
    }

    &_Empty {
      display: grid;
      grid-template-columns: 108px 1fr;
      grid-gap: 18px;
      justify-content: center;
      padding: 10px 15px 15px;
      margin: 11px 0 35px;
      background-color: #e8f5ff;
      border-radius: 6px;
      color: #667085;

      h3 {
        color: #667085;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 5px;
      }

      span {
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }
}
