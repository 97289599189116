@import "src/variables";

.blot-formatter__toolbar-button {
  display: none !important;
}

.SignaturesEditor--ModeHTML {
  .blot-formatter__overlay {
    display: none !important;
  }
}

.blurred-editor div.ql-toolbar {
  display: none
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #ccc !important;
}

.TemplateEditor {
  border-radius: 10px !important;
  padding: 12px;

  &_SubmitButton {
    margin-top: 24px;
    padding: 6px 20px;
    text-align: center;
    background-color: $light-blue;
    color: $primary-black;
    font-size: 14px;
    font-weight: bold;
    font-family: $inter-font;
    border-radius: 4px;
    box-shadow: none;
    width: 100px;
    cursor: pointer;

    &:hover {
      background-color: $light-blue;
      color: $primary-black;
    }

    &:focus {
      background-color: $light-blue;
      color: $primary-black;
    }
  }


  &_Link {
    font-weight: bold;
  }

  &_FormWrapper {

    input:focus {
      outline: none;
      border: none !important;
    }

    input, .ant-select-selector {
      padding: 0 !important;
      color: $primary-black;
    }

    .ant-select-selection-placeholder, .ant-form-item-control-input-content input {
      font-weight: bold;
    }

    .ant-divider {
      margin: 12px 0;
    }

    .ant-form-item {
      margin: 0;
    }
  }

  &__modal {
    position: relative;
    z-index: 9999999 !important;
    border-radius: 10px !important;
  }

  &_Button {
    padding: 12px 4px;
    border-radius: 8px;
    color: $primary-blue;
    font-weight: bold;
  }

  &_Dropdown {
    overflow: scroll;
    max-height: 50vh;
  }

  &__button {
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-blue;
    border-radius: 0 0 10px 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &__text {
    &-button {
      font-weight: bold;
      font-size: 14px;
      color: $white;
    }

    &-mail {
      font-size: 14px;
      color: $dark-blue !important;

      &-type {
        color: $light-gray !important;
      }
    }
  }
}

