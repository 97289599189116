@import "src/variables";

.IntroductionCreator {
  border-radius: 10px !important;
  padding: 24px;

  &_ParticipantWrapper {
    transition: flex 300ms ease-in;
    overflow: hidden;
    margin-right: 15px!important;
  }

  &_FormItem {
    margin: 0!important;
  }

  &_From {
    margin-right: 15px!important;
  }

  &_TextArea {
    margin-top: 24px!important;
    transition: border 200ms ease-in;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid $lighter-gray;
    &:hover {
      border-color: $primary-blue;
    }
  }

  .MaxWidth {
    max-width: 500px;
  }

  &_SubmitButton {
    margin-top: 24px;
    margin-left: 20px;
    padding: 6px 20px;
    text-align: center;
    background-color: $light-blue;
    color: $primary-black;
    font-size: 14px;
    font-weight: bold;
    font-family: $inter-font;
    border-radius: 4px;
    box-shadow: none;
    width: 100px;
    cursor: pointer;

    &:hover {
      background-color: $light-blue;
      color: $primary-black;
    }

    &:focus {
      background-color: $light-blue;
      color: $primary-black;
    }
  }


  &_Link {
    font-weight: bold;
  }

  &_FormWrapper {
    color: $dark-gray;
    margin: 20px !important;

    input:focus {
      outline: none;
      border: none !important;
    }

    input, .ant-select-selector {
      padding: 0 !important;
      color: $primary-black;
    }

    .ant-select-selection-placeholder, .ant-form-item-control-input-content input {
      font-weight: bold;
    }

    .ant-divider {
      margin: 12px 0;
    }

    .ant-form-item {
      margin: 0;
    }
  }

  &__modal {
    position: relative;
    z-index: 9999999 !important;
    border-radius: 10px !important;
  }

  &_Button {
    padding: 12px 4px;
    border-radius: 8px;
    color: $primary-blue;
    font-weight: bold;
  }

  &_Dropdown {
    overflow: scroll;
    max-height: 50vh;
  }

  &__button {
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-blue;
    border-radius: 0 0 10px 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &__text {
    &-button {
      font-weight: bold;
      font-size: 14px;
      color: $white;
    }

    &-mail {
      font-size: 14px;
      color: $dark-blue !important;

      &-type {
        color: $light-gray !important;
      }
    }
  }
}
