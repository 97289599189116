@import "../../../variables.scss";

.ContactDrawerFade {
  animation: 500ms ease-out 0s 1 fade forwards;
  animation-iteration-count: 1;
  opacity: 1;

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.ContactProfileDrawerWrapper {
  z-index: 90 !important;

  &_ContactButtons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    justify-content: space-between;
    margin-bottom: 10px;

    &_Icon {
      margin-right: 12px;

      svg {
        height: 24px;
        width: 24px;
      }
    }

    &_Button,
    &_Button--disabled {
      padding: 8px 12px;
      border: none;
      border-radius: 6px;
      display: flex;
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }

    &_Button {
      background-color: #6ce9a6;
      color: white;

      &:hover {
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;
        cursor: pointer;
        color: white;
      }
    }

    &_Button--disabled {
      button {
        display: flex;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        border: none;
      }

      background-color: #e4ebf2;
      color: #8f8f8f;

      &:hover {
        cursor: not-allowed;
      }
    }

    &_Title {
      font-size: 14px;
    }
  }
}

.ContactProfileDrawer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: $inter-font;
  padding-top: 17px;
  color: $primary-black;

  &_Header {
    flex: 0 0 auto;
    padding: 0 20px;

    &_Archive {
      display: flex;
      color: #646464;
      margin-bottom: 0;
      padding: 0 !important;
      font-size: 15px;

      p {
        margin: 0;
      }

      &_Unarchive {
        margin-left: 4px;
        text-decoration: underline;
        font-style: italic;
        color: $silver-blue !important;
        transition: opacity 0.2s linear;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    &_Buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13px;
      column-gap: 6px;

      div {
        border-radius: 6px;
        flex: 1;
        text-align: center;
        padding: 6px 0;
        display: flex;
        justify-content: center;
        transition: background-color 150ms;
        cursor: pointer;

        svg {
          color: $white;
        }
      }

      &_Save {
        background-color: $primary-green;

        &:hover {
          background-color: $light-green;
        }
      }

      &_Archive {
        background-color: $primary-red;

        &:hover {
          background-color: $lighter-red;
        }
      }

      &_Cancel {
        background-color: $dark-gray;

        &:hover {
          background-color: $light-gray;
        }
      }

      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  &_Sections {
    flex: 1 1 auto;
    overflow-y: scroll;
    margin: 0 9px 0 20px;
    padding-right: 7px;

    &_Archived {
      opacity: 0.5;
    }

    section:last-of-type {
      min-height: calc(100vh - 250px);
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e4ebf2;
      border-radius: 30px;
      height: 240px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    .Section {
      display: grid;
      grid-gap: 12px;
      border-radius: 6px;
      padding: 18px 15px;

      svg {
        margin-right: 5px;
      }

      .Icon--editing {
        svg {
          width: 24px;
          margin-top: 3px;
        }
      }

      &_Pagination {
        &_More {
          border: none;
          background-color: transparent;
          color: $middle-blue;

          &:hover {
            background-color: transparent;
            color: $primary-blue;
            cursor: pointer;
          }

          &:focus {
            background-color: transparent;
            color: $middle-blue;
          }

        }


        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        font-size: 14px;

        ul {
          display: flex;
          align-items: center;

          li {
            height: 20px;
            width: 24px;
            min-width: 24px;
            border: none;
            margin: 0;

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
            }

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 20px;
              width: 24px;
              min-width: 24px;
            }

            a.ant-pagination-item-link {
              display: grid;
              align-items: center;
              justify-content: normal;
              margin-top: -6px;
            }
          }
        }
      }
    }

    section {
      font-size: 13px;

      .inactive {
        color: #8f8f8f;
        line-height: 24px;
      }
    }
  }

  .ant-form-item {
    margin: 0;
  }
}

.DatePicker--hideYear .ant-picker-header > button.ant-picker-header-super-prev-btn,
.DatePicker--hideYear .ant-picker-header > button.ant-picker-header-super-next-btn,
.DatePicker--hideYear .ant-picker-header button.ant-picker-year-btn {
  display: none !important;
}
