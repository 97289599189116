@import "../../variables.scss";

.AccountSettings {
  font-family: $main-font !important;

  &_Nav {
    border-bottom: 1px solid #eaecf0;
  }

  &_Container {
    padding: 0 10px 20vh;
    width: 100%;
    height: calc(100vh - 35px);
    overflow-y: visible;

    h1 {
      font-family: $albertSans-font !important;
      letter-spacing: -1px;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      margin: 10px 0 0;
    }
  }

  &_Header {
    &_Text {
      color: $dark-blue !important;
      font-weight: bold !important;
      font-size: 18px !important;
      font-family: $brandonGrotesque-font;
    }
  }

  &_Sections {
    flex: 1 1 auto;
    height: 110%;
    overflow-x: hidden;

    .BlueButton {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      width: 192px;
      min-width: 192px;
      height: 44px;
      border-radius: 8px !important;
      border: none;
      font-family: $inter-font;
      font-size: 16px;
      font-weight: 500;
      box-shadow: 0 2px 4px 0 rgba(16, 24, 40, 0.15);
      color: #ffffff;
      background: #3fa6f9;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.2s ease-in-out;
      }
    }

    .Wrapper {
      background-color: #f8fafc;
      border-radius: 16px;
      border: 1px solid #e4f3fe;
      padding: 10px 20px 16px;
      margin-bottom: 26px;

      h2 {
        font-family: $albertSans-font;
        letter-spacing: -1px;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }

      .ant-form-item {
        display: grid;

        &-label {
          font-family: $inter-font;
          text-align: left;
          color: #344054;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
        }

        .ant-input,
        .ant-select-selector {
          padding: 10px 14px;
          border-radius: 8px;
          border: 1px solid #d0d5dd;
          box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
          font-family: $inter-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }

        .ant-select-selector,
        .ant-select-selector input {
          display: flex;
          align-items: center;
          height: 46px !important;
        }
      }

      .SubmitButton {
        display: flex;
        padding: 8px 14px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1.6px solid #23cb99;
        font-family: $inter-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        color: #ffffff;
        background: #23cb99;
        margin-left: auto;
        margin-top: 10px;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
          transition: opacity 0.2s ease-in-out;
        }
      }

      .SubmitButton-disabled {
        border: 1.6px solid #818181;
        color: #e0e0e0;
        background: #818181;

        &:hover {
          opacity: 1;
          cursor: not-allowed;
        }
      }
    }

    section:first-child {
      margin-top: 30px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_Form {
    &_Container {
    }

    &_Item {
      margin: 30px 10px 20px 10px !important;
      height: 30px !important;

      .ant-form-item-label label {
        color: $light-gray !important;
        font-size: 13px !important;
      }
    }

    &_ChangePasswordButton {
      color: $light-blue !important;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &_Accounts {
    &_Container {
      padding-top: 40px !important;
    }

    &_Text {
      color: $dark-blue !important;
      font-weight: 600 !important;
      font-size: 18px !important;
    }

    &_StatusIndicator {
      &-connected {
        margin-top: 10px !important;
        width: 5px;
        height: 5px;
        border-radius: 3px;
        background-color: $connected !important;
      }

      &-error {
        margin-top: 5px !important;
        width: 6px;
        height: 6px;
        border-radius: 5px;
        background-color: $error !important;
      }
    }

    &_Table {
      margin-top: 16px !important;
      border: 1px solid #d3d3d3;

      &_Text {
        color: $dark-blue !important;
        font-weight: 600 !important;
        font-size: 14px !important;
      }

      th {
        font-size: 10px !important;
        color: $light-gray !important;
      }

      td {
        font-size: 14px !important;
        color: $dark-blue !important;

        a {
          font-weight: 600 !important;

          &.connect {
            color: $light-blue !important;

            i {
              font-size: 13px !important;
              margin-right: 5px !important;
            }
          }

          &.remove {
            color: $dark-blue !important;

            i {
              font-size: 15px !important;
              margin-right: 5px !important;
            }
          }
        }
      }
    }
  }

  &_AddButton {
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    &-icon {
      font-size: 16px !important;
      color: $light-blue !important;
      margin-right: 5px !important;
    }

    &-text {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: $primary-black !important;
    }
  }

  &_ImportButton {
    padding: 20px 0 !important;

    &:hover {
      cursor: pointer;
    }

    &-icon {
      font-size: 16px !important;
      color: $light-blue !important;
      margin-right: 5px !important;
    }

    &-text {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: $primary-black !important;
    }
  }

  &_Teams {
    min-height: 820px;
  }
}

.ant-spin-dot-item {
  background-color: $light-blue;
}

.underline {
  padding-bottom: 5px;
  --bg-h: 2px !important;
  background: linear-gradient(0deg, $light-blue, $light-blue) no-repeat right bottom /
    0 var(--bg-h);
  transition: background-size 350ms;
  text-decoration: none;
  color: inherit;
  line-height: 1;

  &:hover {
    background-size: 100% var(--bg-h) !important;
    background-position-x: left !important;
  }
}
