@import "../../../variables";

.CustomPagination {
  min-width: 300px !important;
  padding: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    border: none;
    cursor: pointer;
  }

  .anticon-double-right, .anticon-double-left {
    margin-top: 15px;
  }

  .ant-pagination-item-ellipsis {
    color: white !important;

    &:after {
      content: "...";
      position: absolute;
      color: #000000;
      top: 5px;
      left: 6px;
    }
  }

  &_Page {
    cursor: pointer;
    width: 40px;
    height: 40px;
    font-family: $inter-font;
    border-radius: 8px;
    border: none;
    background-color: transparent;
    transition: background-color .2s linear;

    &:hover {
      background: #DEF0FF;
    }

  }

  .currentPage {
    background: #DEF0FF;
    color: $primary-blue;
  }

  .ant-pagination-prev, .ant-pagination-next {
    flex: 1;
    cursor: default !important;

    button {
      border-color: #D0D5DD !important;
    }
  }

  .ant-pagination-prev button {
    float: left;
    padding: 8px 14px 8px 6px;
  }

  .ant-pagination-next button {
    float: right;
    padding: 8px 6px 8px 14px;
  }

  &_Button {
    font-family: $inter-font;
    line-height: 20px;
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    transition: background-color .2s linear;

    &:hover {
      background-color: #F2F4FA;
    }

    svg {
      margin: 0 12px;
    }
  }

  &-small {
    margin: 25px auto 0 !important;
    height: 30px;
    width: 430px !important;

    li {
      height: 30px !important;
      width: 30px !important;
      margin-right: 2px !important;
    }

    .anticon-double-right, .anticon-double-left {
      margin-top: 9px;
    }

    .CustomPagination {
      &_Page {
        cursor: pointer;
        width: 30px;
        height: 30px;
      }

      &_Button {
        svg {
          width: 10px;
          height: 10px;
          margin: 2px 14px;
        }
      }
    }

    .ant-pagination-prev button {
      float: left;
      padding: 4px 0;
    }

    .ant-pagination-next button {
      float: right;
      padding: 4px 0;
    }
  }
}
