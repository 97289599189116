@import "src/variables";

.LinkedinModal {
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
  }

  &_LoadingWrapper {
    width: 100%;
    height: 250px;
    display: grid;
    align-items: center;
    justify-content: center;
  }

  h1,
  h2 {
    font-family: $albertSans-font;
    letter-spacing: -1px;
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 6px;
    text-align: center;
    color: #101828;
  }

  span {
    font-family: $inter-font;
    color: #344054;
    font-weight: 500;
  }

  &_CloseIcon {
    svg {
      color: black;
      height: 21px;
      width: 21px;

      &:hover {
        transition: opacity 0.2s ease-in-out;
        opacity: 0.7;
      }
    }
  }

  &_Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 15px;

    span {
      color: #101828;
      text-align: left;
      font-size: 18px;
    }
  }

  &_Sync {
    text-align: left;
    text-decoration: underline;
    font-weight: bold;
    color: #1c9aff !important;
  }

  &_Body {
    padding: 10px 50px;
    color: #667085;
    font-size: 14px;

    &__underline {
      text-decoration: underline;
    }
  }

  &_Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 30px 0;

    span {
      color: white;
    }

    &_Connected {
      background-color: white !important;
      color: #1c9aff !important;
      border: 1px solid #1c9aff !important;

      span {
        color: #1c9aff !important;
      }

      &:hover {
        transition: opacity 0.2s ease-in-out;
        opacity: 0.7;
      }
    }

    a {
      margin: 0 50px;
      width: 100%;
      text-align: center;
      color: white;
      background-color: #1c9aff;
      border: none;
      border-radius: 8px;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      padding: 8px 14px;
      font-weight: 500;
      font-size: 14px;
      font-family: $inter-font;

      &:hover {
        transition: opacity 0.2s ease-in-out;
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }

  &_Configure {
    background-color: transparent;

    &_Header {
    }

    &_Content {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 10px;
    }
  }
}
